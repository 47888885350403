import { Route, Switch } from 'react-router-dom';
import React from 'react';
import SearchResults from './searchResults';
import { TopBarSearchApps } from './apps';
import { TopBarSearchClients } from './clients';

import local from './local';
import searchView from './searchView';

export default {
  routes: [
    <Route key="searchView" exact path="/" component={searchView} />,
    <Route key="searchResults" exact path="/search/:segment/:type/:term?" component={SearchResults} />,
  ],

  topCenterBar: [
    <Switch key="search">
      <Route
        exact
        path="/client/:objectId/info"
        component={props => (
          <TopBarSearchClients
            {...props}
            segment="client"
            hideTotal
            placeholder="Find client by username, email or pin"
          />
        )}
      />

      <Route
        exact
        path="/client/:objectId/activity"
        component={props => (
          <TopBarSearchClients
            {...props}
            segment="client"
            hideTotal
            placeholder="Find client by username, email or pin"
          />
        )}
      />

      <Route
        exact
        path="/search/client/:type/:term?"
        component={props => (
          <TopBarSearchClients
            {...props}
            segment="client"
            placeholder="Find client by username, email or pin"
          />
        )}
      />

      <Route
        exact
        path="/app/:objectId/info"
        component={props => (
          <TopBarSearchClients
            {...props}
            segment="app"
            hideTotal
            placeholder="Find aplication by name or serviceName"
          />
        )}
      />

      <Route
        exact
        path="/search/app/:type/:term?"
        component={props => (
          <TopBarSearchApps
            {...props}
            segment="app"
            placeholder="Find aplication by name or serviceName"
          />
        )}
      />
    </Switch>,
  ],

  ...local,
};
