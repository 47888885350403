import { graphql } from 'react-apollo';

import { queryErrorsToArray } from 'utils/misc';
import { ClientInfo } from './graphql/client.graphql';
import { ActivityLog } from './graphql/activity.graphql';

const withClientInfo = graphql(ClientInfo, {
  props: ({ data: { viewer, error, loading } }) => (
    {
      errors: queryErrorsToArray(error),
      loading,
      data: viewer,
    }
  ),
  options: props => ({
    // pollInterval: 3000,
    variables: {
      id: props.objectId,
      input: {
        last: 10,
      },
    },
  }),
});

export const withActivityLog = graphql(ActivityLog, {
  props: ({ data: { viewer, error, loading, fetchMore } }) => ({
    errors: queryErrorsToArray(error),
    loading,
    data: viewer,
    fetchMore,
  }),
  options: props => ({
    // pollInterval: 3000,
    variables: {
      id: props.objectId,
      input: {
        after: props.after,
        before: props.before,
        first: props.first,
        last: props.last,
      },
    },
  }),
});

export default withClientInfo;
