import React from 'react';
import Results from './Results';

const View = ({ match }) => {
  const { ticketId } = match.params;

  return <Results id={ticketId} />;
};

export default View;
