import React, { Fragment } from 'react';
import { showWhenAdmin, showWhenSupport } from 'app/Auth';
import Results from './Results';

const AdminView = showWhenAdmin(Results);
const SupportView = showWhenSupport(Results);

const View = ({ match }) => {
  const { objectId } = match.params;

  return (
    <Fragment>
      <AdminView objectId={objectId} />
      <SupportView objectId={objectId} />
    </Fragment>
  );
};

export default View;
