import React, { Component } from 'react';
import Tab from './Tab';

import styles from './index.scss';

class Tabs extends Component {
  constructor(props) {
    super(props);


    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { children } = this.props;

    const ch = children.filter(c => c);

    let activeTab;
    let childrens;
    if (Array.isArray(ch)) {
      activeTab = ch[0].props.label;
      childrens = ch;
    } else {
      activeTab = ch.props.label;
      childrens = [ch];
    }

    this.setState({
      activeTab,
      childrens,
      loading: false,
    });
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      onClickTabItem,
      props: {
        contentClassName,
      },
      state: {
        activeTab,
        childrens,
        loading,
      },
    } = this;

    if (loading) return null;

    return (
      <div className={styles.tabs}>
        <ol className={styles.tabList}>
          {childrens.map((child) => {
            const { label, status } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                status={status}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className={[styles.content, contentClassName || ''].join(' ')}>
          {
            childrens.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })
          }
        </div>
      </div>
    );
  }
}

export default Tabs;
