import React, { Component } from 'react';
import classnames from 'classnames';
import Tooltip from 'react-autotip';

import styles from './index.scss';


class ProgressPie extends Component {
  async componentDidMount() {
    const { min } = this.props;

    this.setState({
      currentPods: min,
    });
  }

  render() {
    const { min, max, highlyAvailable, autoscaling, currentPods, className } = this.props;

    const percentage = Math.floor((min / max) * 100);
    const percentageCurrent = Math.floor((currentPods / max) * 100);

    return (
      <div
        className={classnames(styles.radial, className, {
          [styles.highlyAvailable]: highlyAvailable,
          [styles.activateOrange]: currentPods > min,
        })}
      >
        <div className={styles.radialProgressBarWrapper}>

          <svg className={styles.svg} viewBox="0 0 128 112" width="160" height="140">
            <path className={styles.radialProgressBackground} d="M27.7,104.5C16.1,94.4,8.7,79.4,8.7,62.8C8.7,32.2,33.5,7.4,64,7.4s55.3,24.8,55.3,55.3 c0,16.7-7.4,31.7-19.1,41.8" />
            <path className={[styles.radialProgressBar, styles.current].join(' ')} stroke="url(#gradient)" data-percent={percentageCurrent} d="M27.7,104.5C16.1,94.4,8.7,79.4,8.7,62.8C8.7,32.2,33.5,7.4,64,7.4s55.3,24.8,55.3,55.3 c0,16.7-7.4,31.7-19.1,41.8" />
            <path className={[styles.radialProgressBar, styles.minEngines].join(' ')} data-percent={percentage} d="M27.7,104.5C16.1,94.4,8.7,79.4,8.7,62.8C8.7,32.2,33.5,7.4,64,7.4s55.3,24.8,55.3,55.3 c0,16.7-7.4,31.7-19.1,41.8" />
          </svg>


          {
            highlyAvailable
            && (
              <svg className={styles.haText} viewBox="0 0 128 112">
                <defs>
                  <path id="circlePath" d="M27.7,104.5C16.1,94.4,8.7,79.4,8.7,62.8C8.7,32.2,33.5,7.4,64,7.4s55.3,24.8,55.3,55.3 c0,16.7-7.4,31.7-19.1,41.8" />
                </defs>
                <text>
                  <textPath xlinkHref="#circlePath">High Availability</textPath>
                </text>
              </svg>
            )
          }

          <div className={[styles.minmax, styles.min, styles.show].join(' ')}>
            <Tooltip
              text="Control redundancy levels of your app by choosing a minimum number of available engines."
              tooltipStyles={{
                color: '#fff',
                fontFamily: 'Ubuntu',
                fontSize: 14,
                fontWeigt: 300,
                borderRadius: 4,
                maxWidth: 500,
                width: 340,
                unit: 4,
                lightgrey: '#222933',
                opaqueGrey: '#222933',
              }}
            >
              <span>{min}</span>
            </Tooltip>
          </div>

          <div className={[
            styles.minmax,
            styles.max,
            styles.show,
            !autoscaling && min === max ? styles.blue : '',
            !autoscaling && highlyAvailable ? styles.green : '',
          ].join(' ')}
          >
            <Tooltip
              text="The maximum number of the engines is managed by SashiDo's system for security reasons and abuse prevention. Limits can be increased upon request."
              tooltipStyles={{
                color: '#fff',
                fontFamily: 'Ubuntu',
                fontSize: 14,
                fontWeigt: 300,
                borderRadius: 4,
                maxWidth: 500,
                width: 340,
                unit: 4,
                lightgrey: '#222933',
                opaqueGrey: '#222933',
              }}
            >
              <span>{max}</span>
            </Tooltip>
          </div>

          <div className={styles.containers}>
            {currentPods > 1 ? 'active engines' : 'active engine'}
          </div>

          <div className={styles.radialProgressContainers}>
            {currentPods}
          </div>
        </div>
      </div>
    );
  }
}


export default ProgressPie;
