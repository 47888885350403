import React from 'react';

import styles from './index.scss';

const Notify = ({ success, error, message }) => {
  if (!message) return null;

  if (success) {
    return (
      <p className={styles.success}>
        {message}
      </p>
    );
  }

  if (error) {
    return (
      <p className={styles.error}>
        {message}
      </p>
    );
  }

  return null;
};

export default Notify;
