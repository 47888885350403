import { graphql } from 'react-apollo';

import { compose } from 'utils/compose';
import ScreenLoading from 'components/ScreenLoading';

import {
  GetLoading,
  StartLoading,
  StopLoading,
  ResetLoading,
} from './graphql/loading.local.graphql';


// Provide screenLoading prop, mounted prop will change on first boot
export const withScreenLoading = graphql(GetLoading, {
  props: ({ data: { screen } }) => ({
    screenLoading: screen.loading,
    screenStopLoading: screen.stopLoading,
    screenMounted: screen.mounted,
  }),
});

// Provide startLoading, stopLoading and resetLoading func props
export const withManageScreenLoading = compose(
  graphql(StartLoading, {
    props: ({ mutate }) => ({ startLoading: mutate }),
  }),
  graphql(StopLoading, {
    props: ({ mutate }) => ({ stopLoading: mutate }),
  }),
  graphql(ResetLoading, {
    props: ({ mutate }) => ({ resetLoading: mutate }),
  })
);

export default compose(
  withScreenLoading,
  withManageScreenLoading
)(ScreenLoading);
