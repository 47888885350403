import React from 'react';
import ScreenErrors from 'components/ScreenErrors';
import Loader from 'components/Loader';
import Details from '../client/details';
import withUserInfo from '../client/enhancers';
import styles from './index.scss';

const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { client } = data || {};

  return (
    <div className={styles.loadingHolder}>
      <Loader loading={loading} backgroundColor="#fff" padding="20px">
        <Details data={client} />
      </Loader>
    </div>
  );
};

export default withUserInfo(Results);
