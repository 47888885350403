exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".details_123{display:flex;font-size:14px}.details_123>div{position:absolute;top:10px;right:10px;bottom:10px;left:10px}.tabHolder_1EV{position:absolute;top:42px;right:0;bottom:0;left:0;padding:40px 20px;overflow:auto;background-color:#fff}.tabHolder_1EV .tabHolderInner_3kY pre{white-space:pre-wrap}.loadingHolder_1zT{position:absolute;top:0;right:0;bottom:0;left:0;padding:40px 20px;overflow:auto;background-color:#fff}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/ticketing/src/modules/ticketing/index.scss"],"names":[],"mappings":"AAAA,aACE,aACA,cAAe,CAFjB,iBAKI,kBACA,SACA,WACA,YACA,SAAU,CACX,eAID,kBACA,SACA,QACA,SACA,OACA,kBACA,cACA,qBAAsB,CARxB,uCAYM,oBAAqB,CACtB,mBAKH,kBACA,MACA,QACA,SACA,OACA,kBACA,cACA,qBAAsB,CACvB","file":"index.scss","sourcesContent":[".details {\n  display: flex;\n  font-size: 14px;\n\n  > div {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    bottom: 10px;\n    left: 10px;\n  }\n}\n\n.tabHolder {\n  position: absolute;\n  top: 42px;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  padding: 40px 20px;\n  overflow: auto;\n  background-color: #fff;\n\n  .tabHolderInner {\n    pre {\n      white-space: pre-wrap;\n    }\n  }\n}\n\n.loadingHolder {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  padding: 40px 20px;\n  overflow: auto;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"details": "details_123",
	"tabHolder": "tabHolder_1EV",
	"tabHolderInner": "tabHolderInner_3kY",
	"loadingHolder": "loadingHolder_1zT"
};