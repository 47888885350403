import auth from './auth';

// import playground from './playground';
import app from './app';
import client from './client';
import search from './search';
import ticketing from './ticketing';

import def from './default';

// Gather Apollo client defaults from modules
export const defaults = {
  ...auth.defaults,
  ...def.defaults,
  ...search.defaults,
  ...ticketing.defaults,
};

// Gather Apollo client mutation resolvers from modules
export const Mutation = {
  ...auth.Mutation,
  ...def.Mutation,
  ...search.Mutation,
  ...ticketing.Mutation,
};

export const Query = {};

export default {
  routes: [
    // ...playground.routes,
    ...app.routes,
    ...client.routes,
    ...auth.routes,
    ...search.routes,
    ...ticketing.routes,

    // WARNING - keep always last!!!
    ...def.routes,
  ],
  topCenterBar: [
    ...search.topCenterBar,
  ],
  topRightBar: [
    ...auth.topRightBar,
  ],

  useAsIframe: [
    ...ticketing.useAsIframe,
  ],
};
