exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".hr_2oS{height:1px;margin:10px;border-bottom:1px dotted #caced7}.copy_y0j{display:flex;justify-content:center;align-items:center}.copy_y0j>span{display:flex;justify-content:center;align-items:center}.copy_y0j svg{fill:#597286}.copy_y0j:hover svg{fill:#0092ff}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/Copy/src/assets/css/_globals.scss","/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/Copy/src/components/Copy/index.scss"],"names":[],"mappings":"AAwCA,QACE,WACA,YACA,gCAAiC,CCzCnC,UACE,aACA,uBACA,kBAAmB,CAHrB,eAMI,aACA,uBACA,kBAAmB,CARvB,cAYI,YAAa,CAZjB,oBAiBM,YDNQ,CCOT","file":"index.scss","sourcesContent":["$fontFamily: 'Ubuntu', sans-serif;\n\n$bg: #f1f4f8;\n$borderColor: #EAEDF3;\n$borderColorDarker: #D8DCE6;\n\n\n$textMain: #3E3F42;\n$textBase: #6B6C6F;\n$textSecondary: #9EA0A5;\n\n\n$green: #38B249;\n$blue: #0092ff;\n$orange: #F6AB2F;\n$red: #E6492D;\n\n$success: #38B249;\n$primary: #00a9ff;\n$warning: #F6AB2F;\n$error: #E6492D;\n\n$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);\n\n\n@mixin user-noselect {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n@mixin ellipsis() {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.hr {\n  height: 1px;\n  margin: 10px;\n  border-bottom: 1px dotted #caced7;\n}\n","@import '~assets/css/_globals';\n\n.copy {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  > span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  svg {\n    fill: #597286;\n  }\n\n  &:hover {\n    svg {\n      fill: $blue;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"hr": "hr_2oS",
	"copy": "copy_y0j"
};