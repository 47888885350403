export default {
  Mutation: {
    setAppsPageInfo(_, { info }, { cache }) {
      try {
        cache.writeData({ data: { appsPageInfo: info }, __typename: 'AppsPageInfo' });
      } catch (e) {
        console.log('error', e);
      }

      return null;
    },
  },
  defaults: {
    appsPageInfo: null,
  },
};
