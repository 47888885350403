import React, { PureComponent } from 'react';
import classnames from 'classnames';

import cls from './button.scss';

class Button extends PureComponent {
  state = {};

  static defaultProps = {
    Component: 'button',
    primary: false,
    warning: false,
    error: false,
    success: false,
    dark: false,
    className: null,
    contentClassName: null,
    clear: false,
    whiteBorder: false,
    href: null,
    to: null,
    xs: false,
    lg: false,
    isLoading: false,
    type: 'button',
    children: null,
  }

  render() {
    const {
      Component,
      primary,
      warning,
      error,
      success,
      dark,
      children,
      className,
      contentClassName,
      whiteBorder,
      xs,
      lg,
      clear,
      isLoading,
      type,
      ...rest
    } = this.props;

    const classes = classnames(
      cls.base,
      {
        [cls.primary]: primary,
        [cls.warning]: warning,
        [cls.error]: error,
        [cls.success]: success,
        [cls.xs]: xs,
        [cls.lg]: lg,
        [cls.clear]: clear,
        [cls.whiteBorder]: whiteBorder,
        [cls.isLoading]: isLoading,
        [cls.dark]: dark,
      },
      className
    );

    const btnProps = {
      className: classes,
      ...rest,
    };

    return (
      <Component {...btnProps} type={Component === 'button' ? type : null}>
        <div className={classnames(cls.content, contentClassName)}>
          {children}
        </div>
      </Component>
    );
  }
}

export default Button;
