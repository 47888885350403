import React from 'react';
import { toDate, SplitTimeToSeconds } from 'utils/misc';
import styles from './index.scss';


const Builds = ({ data }) => {
  const renderRows = () => {
    const rows = data.map((item, i) => (
        <tr key={i}>
          <td>{item.number}</td>
          <td>{item.name}</td>
          <td>{item.phase}</td>
          <td>{SplitTimeToSeconds(item.duration, 'nanoseconds')}</td>
          <td>{toDate(item.createdAt)}</td>
        </tr>
    ));

    return rows;
  };

  return (
    <table className={styles.table}>
      <colgroup>
        <col width="5%" />
        <col width="35%" />
        <col width="20%" />
        <col width="20%" />
        <col width="20%" />
      </colgroup>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phase</th>
          <th>Duration</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </table>
  );
};

export default Builds;
