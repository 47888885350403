exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".status_Gcw{display:flex;justify-content:center;align-items:center;padding:3px 10px;color:#fff;font-size:9px;letter-spacing:.1px;text-align:center;text-transform:uppercase;border-radius:30px;max-height:16px}.status_Gcw.active_28G{background-color:#68ac23}.status_Gcw.suspended_1Vs{background-color:#dea928}.status_Gcw.deleted_2JG{background-color:#cc3434}.status_Gcw.terminated_2ob{background-color:#cc3434}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/AppStatus/src/components/AppStatus/index.scss"],"names":[],"mappings":"AAAA,YACE,aACA,uBACA,mBACA,iBACA,WACA,cACA,oBACA,kBACA,yBACA,mBACA,eAAgB,CAXlB,uBAcI,wBAAyB,CAd7B,0BAkBI,wBAAyB,CAlB7B,wBAsBI,wBAAyB,CAtB7B,2BA0BI,wBAAyB,CAC1B","file":"index.scss","sourcesContent":[".status {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 3px 10px;\n  color: #fff;\n  font-size: 9px;\n  letter-spacing: .1px;\n  text-align: center;\n  text-transform: uppercase;\n  border-radius: 30px;\n  max-height: 16px;\n\n  &.active {\n    background-color: #68ac23;\n  }\n\n  &.suspended {\n    background-color: #dea928;\n  }\n\n  &.deleted {\n    background-color: #cc3434;\n  }\n\n  &.terminated {\n    background-color: #cc3434;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"status": "status_Gcw",
	"active": "active_28G",
	"suspended": "suspended_1Vs",
	"deleted": "deleted_2JG",
	"terminated": "terminated_2ob"
};