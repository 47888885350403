import React from 'react';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar';
import Row from 'components/Row';
import AppStatus from 'components/AppStatus';
import { showWhenAdmin } from 'app/Auth';
import DropDown from './components/DropDown';
import OpenShiftURLs from './components/openShiftURLs';
import styles from './index.scss';


const AppInfoShort = ({ data }) => {
  const appOwner = ({ client: { useGravatar, image, email, id } }) => {
    const imageURL = () => {
      if (image) {
        return `https://api-parseground.s3.amazonaws.com/${image}`;
      }

      return '/assets/images/avatar.png';
    };

    const path = `/client/${id}/info`;
    return (
      <Link
        to={path}
        className={styles.type}
      >
        {
          useGravatar && !image
            ? <Gravatar email={email} size={30} default="mm" />
            : <img src={imageURL()} alt={email} title={email} />
        }
        <span>{email}</span>
      </Link>
    );
  };

  const rows = () => {
    const AdminOnly = showWhenAdmin(() => [
      <Row key="databaseUri" title="Database Connection String" value={data.databaseUri} />,
    ]);

    const result = [
      <Row key="hasRedis" title="Redis" value={data.appSettings.hasRedis} />,
      <Row key="serviceName" title="Service Name" value={data.serviceName} />,
      <Row key="serverUrl" title="Server URL" value={data.serverUrl} />,
      <AdminOnly key="AdminOnly" />,
    ];

    return result;
  };

  const openShiftView = () => (
    <div className={styles.openshift}>
      <OpenShiftURLs region={data.region} serviceName={data.serviceName} />
    </div>
  );

  return (
    <div className={styles.app} key={data.objectId}>
      <div className={styles.head}>
        <Link to={`/app/${data.id}/info`} className={styles.iconHolder}>
          <AppStatus status={data.status} />
          <img
            alt={data.name}
            src={data.icon ? `https://api-parseground.s3.amazonaws.com/${data.icon}` : '/assets/images/default_app_icon.png'}
            className={styles.appIcon}
          />
        </Link>
        <div>
          <h3><Link to={`/app/${data.id}/info`}>{data.name}</Link></h3>
          {appOwner(data)}
        </div>

        <DropDown
          collaborators={data.appSettings.collaborators}
          ownerId={data.client.id}
          appId={data.id}
        >
          Collaborators
        </DropDown>

        <span
          className={styles.region}
          key="RegionView"
        >
          {data.region}
        </span>
      </div>
      <div className={styles.details}>
        {openShiftView()}
        {rows()}
      </div>
    </div>
  );
};

export default AppInfoShort;
