import React, { Fragment } from 'react';
import { withRouterLocationAndMatch } from 'utils/compose';

import Header from './Header';
import styles from './index.scss';

const Layout = ({
  children,
  topCenterBar = [],
  topRightBar = [],
  screenLoading,
  useAsIframe = [],
  location,
}) => {
  // todo @pivanov
  const sux = location.pathname.startsWith(useAsIframe[0]);
  if (sux) {
    return (
      <Fragment>
        <div className={styles.useAsIframe}>
          <div className={[styles.content, screenLoading ? styles.blur : ''].join(' ')}>
            {children}
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={styles.app}>
        <Header topCenterBar={topCenterBar} topRightBar={topRightBar} />
        <div className={[styles.content, screenLoading ? styles.blur : ''].join(' ')}>
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default withRouterLocationAndMatch(Layout);
