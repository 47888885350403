import React from 'react';
import { graphql } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { withEither, compose, mapProps } from 'utils/compose';

import { withScreenLoading } from 'app/ScreenLoading';
import { GetAuth, UpdateAuth } from './graphql/auth.local.graphql';

// Provide auth prop
export const withAuth = graphql(GetAuth, {
  props: ({ data }) => ({ auth: data.auth }),
});

// Provide setSession func prop
export const withUpdateAuth = graphql(UpdateAuth, {
  props: ({ mutate }) => ({
    setSession: (loggedIn, user) => (
      mutate({ variables: { loggedIn, user } })
    ),
  }),
});

// Redirect to given route if not authorized yet
export const redirectWhenNotAuthorized = (route = '/login') => compose(
  withAuth,
  withScreenLoading,
  withEither(
    ({ auth, screenLoading }) => auth.loggedIn !== true && !screenLoading,
    () => <Redirect to={route} />
  ),
  mapProps(({ auth, ...props }) => ({ ...props }))
);

// Redirect to given route if already authorized
export const redirectWhenAuthorized = (route = '/') => compose(
  withAuth,
  withScreenLoading,
  withEither(
    ({ auth, screenLoading }) => auth.loggedIn === true && !screenLoading,
    () => <Redirect to={route} />
  ),
  mapProps(({ auth, ...props }) => ({ ...props }))
);

// Redirect to given route if already authorized
export const showWhenAuthorized = compose(
  withAuth,
  withEither(
    ({ auth }) => auth.loggedIn !== true,
    () => null
  ),
  mapProps(({ auth, ...props }) => ({ ...props }))
);

export const showWhenAdmin = compose(
  withAuth,
  withEither(
    ({ auth }) => (auth.user || {}).role !== 'ADMIN',
    () => null
  ),
  mapProps(({ auth, ...props }) => ({ ...props }))
);


export const showWhenSupport = compose(
  withAuth,
  withEither(
    ({ auth }) => (auth.user || {}).role !== 'SUPPORT',
    () => null
  ),
  mapProps(({ auth, ...props }) => ({ ...props }))
);
