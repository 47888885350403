import { gql } from 'apollo-boost';

export default {
  Mutation: {
    resetLogin(_, __, { cache }) {
      cache.writeData({
        data: {
          login: {
            __typename: 'Login',
            message: '',
            error: '',
            email: '',
          },
        },
      });
      return null;
    },
    setLoginMessage(_, { message }, { cache }) {
      const id = '$ROOT_QUERY.login';
      const fragment = gql`
            fragment loginMessageFragment on Login {
              message
            }
          `;
      const data = { message, __typename: 'Login' };
      cache.writeFragment({ fragment, id, data });
      return null;
    },
    setLoginEmail(_, { email }, { cache }) {
      const id = '$ROOT_QUERY.login';
      const fragment = gql`
            fragment loginEmailFragment on Login {
              email
            }
          `;
      const data = { email, __typename: 'Login' };
      cache.writeFragment({ fragment, id, data });
      return null;
    },
    setLoginError(_, { error }, { cache }) {
      const id = '$ROOT_QUERY.login';
      const fragment = gql`
            fragment loginErrorFragment on Login {
              message
            }
          `;
      const data = { error, __typename: 'Login' };
      cache.writeFragment({ fragment, id, data });
      return null;
    },
  },
  defaults: {
    // Login form central storage
    login: {
      __typename: 'Login',
      message: '',
      error: '',
      email: '',
    },
  },
};
