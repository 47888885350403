import React, { Component } from 'react';
import Gravatar from 'react-gravatar';
import styles from './index.scss';

const menuItems = [
  {
    name: 'Logout',
    href: '/logout',
  },
];

class DropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleKey(e) {
    const keyCode = e.keyCode || e.which;
    switch (keyCode) {
      case 27:
        this.setState({ open: false });
        break;
      default:
        break;
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  toggleClick() {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  render() {
    const classes = [styles.container];
    const { open } = this.state;
    const { user, children } = this.props;
    const { email, profile } = user;
    const { useGravatar, image } = profile;

    return (
      <div className={classes.join(' ')} ref={this.setWrapperRef}>
        <div className={[styles.btn, open ? styles.open : ''].join(' ')}>
          {
            user
              ? (
                  <div
                    role="button"
                    tabIndex="-1"
                    className={styles.avatar}
                    onClick={() => this.toggleClick()}
                    onKeyPress={() => this.toggleClick()}
                  >
                    {
                      useGravatar || !image
                        ? <Gravatar email={email} size={40} default="mm" />
                        : <img src={`https://api-parseground.s3.amazonaws.com/${image}`} alt={email} />
                    }
                  </div>

              )
              : (
                <span role="presentation" onClick={() => this.toggleClick()} onKeyPress={() => this.toggleClick()}>
                  {children}
                </span>
              )
          }

          <ul className={styles.dropdown}>
            <li><a href="#">Profile (WIP)</a></li>
            {
              menuItems.map((link, i) => {
                const key = i;
                return (
                  <li key={key}>
                    <a href={link.href}>
                      {link.name}
                    </a>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    );
  }
}


export default DropDownMenu;
