const messagesMinLength = "This field can't contain less than {min} characters";
const messageMaxLength = "This field can't contain more than {max} characters";
const messageFieldRequired = 'This is a required field';
const messageMailError = 'A valid email is required';
const messageMinmaxLength = "This field can't contain less than {min} and more than {max} characters";

export const parseMessage = ({ message, values }) => {
  if (!values || Object.keys(values).length === 0) {
    return message;
  }
  if (!(message || '').match(/\{\w+\}/gm)) {
    return message;
  }

  let result = message;
  for (const key of Object.keys(values)) {
    const find = new RegExp(`{${key}}`, 'gm');
    const replace = values[key];
    result = result.replace(find, replace);
  }
  return result;
};

const minLength = ({ min, message }) => (value) => {
  if (typeof value === 'string' && value.trim().length < min) {
    return { message: message || messagesMinLength, values: { min } };
  }
  return false;
};
const maxLength = ({ max, message }) => (value) => {
  if (typeof value === 'string' && value.trim().length > max) {
    return { message: message || messageMaxLength, values: { max } };
  }
  return false;
};

export default {
  required: (options = {}) => (value) => {
    const val = typeof value === 'string' ? value.trim() : value;
    if (!val) {
      return { message: options.message || messageFieldRequired };
    }
    return false;
  },
  // VERY basic but cheap
  email: (options = {}) => (value) => {
    if (typeof value !== 'string') {
      return false;
    }
    const [name = '', fqdn = ''] = value.split('@', 2);
    const test = (name.length > 0 && fqdn.length > 0);
    if (!test) {
      return { message: options.message || messageMailError };
    }
    return false;
  },
  minLength,
  maxLength,
  minmaxLength: ({ min, max, message }) => (value) => {
    const minResult = minLength({ min })(value);
    const maxResult = !minResult ? maxLength({ max })(value) : false;
    if (minResult || maxResult) {
      return { message: message || messageMinmaxLength, values: { min, max } };
    }
    return false;
  },
};
