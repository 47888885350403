import React from 'react';
import ActivityResults from './ActivityResults';

const Activity = ({ match }) => {
  const { objectId } = match.params;

  return (
    <ActivityResults
      objectId={objectId}
      last={20}
    />
  );
};

export default Activity;
