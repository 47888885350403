exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".searchForm_3u1{display:flex;width:300px}.searchForm_3u1>button{height:48px;margin-left:20px}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/search/components/Form/src/modules/search/components/Form/index.scss"],"names":[],"mappings":"AAAA,gBACE,aACA,WAAY,CAFd,uBAKI,YACA,gBAAiB,CAClB","file":"index.scss","sourcesContent":[".searchForm {\n  display: flex;\n  width: 300px;\n\n  > button {\n    height: 48px;\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"searchForm": "searchForm_3u1"
};