import React from 'react';
import ScreenErrors from 'components/ScreenErrors';
import Loader from 'components/Loader';
import withUserInfo from './enhancers';
import Details from './details';


const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { client } = data || {};

  return (
    <Loader loading={loading} padding="40px 20px">
      <Details data={client} />
    </Loader>
  );
};

export default withUserInfo(Results);
