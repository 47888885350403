import React from 'react';
import curry from 'lodash/curry';
import set from 'lodash/set';
import { parseMessage } from 'utils/validations';
import { handleGraphQLErrors } from 'utils/misc';

// Convert fields like { 'name.first': value } to { name: { first: value } }
export function fieldsToPayload(fields) {
  return Object.keys(fields).reduce((acc, key) => {
    const value = fields[key];
    return set(acc, key, value);
  }, {});
}

export const setError = curry((field, err, state) => {
  if (!err) {
    return {
      ...state,
      errors: Object.keys(state.errors || {})
        .filter(f => f !== field)
        .reduce((acc, f) => ({
          ...acc,
          [f]: state.errors[f],
        }), {}),
    };
  }
  return {
    ...state,
    errors: {
      ...state.errors,
      [field]: err,
    },
  };
});

export const setStateVal = curry((prop, value, state) => ({
  ...state,
  fields: {
    ...state.fields,
    [prop]: value,
  },
}));

export const setStateArray = curry((prop, checked, value, state) => {
  const arrValue = ((state.fields || {})[prop] || [])
    .filter(v => v !== value);

  if (checked) {
    arrValue.push(value);
  }

  return {
    ...state,
    fields: {
      ...state.fields,
      [prop]: arrValue,
    },
  };
});

const noopFn = () => { };

export const getFieldErrors = (fields, errors) => {
  const fieldNames = Object.keys(fields);
  let hasError = false;

  // Normalize field errors
  const fieldErrors = fieldNames.reduce((acc, name) => {
    // Validation or native JS error
    if (typeof errors[name] === 'object' && errors[name].message) {
      hasError = true;
      return { ...acc, [name]: parseMessage(errors[name]) };
    }
    // String message
    if (errors[name]) {
      hasError = true;
      return { ...acc, [name]: errors[name] };
    }
    return acc;
  }, {});

  // find any custom errors and add them
  for (const name of Object.keys(errors)) {
    if (errors[name] && typeof fieldErrors[name] === 'undefined') {
      fieldErrors[name] = typeof errors[name] === 'string'
        ? errors[name]
        : errors[name].message;
    }
  }

  return {
    errors: fieldErrors,
    hasError,
  };
};

export class FormHandler {
  constructor(reactComponentInstance, {
    validations = {},
    submit,
    onSuccess,
    onError,
    onValidateErrors,
    onValidateBeforeSubmit,
  }) {
    const { setState, state } = reactComponentInstance;
    this.fields = Object.keys(state.fields || {});
    this.setState = setState.bind(reactComponentInstance);
    this.validations = validations;
    this.onSuccess = onSuccess || noopFn;
    this.onError = onError || noopFn;
    // when validation error occures
    this.onValidateErrors = onValidateErrors || noopFn;
    // when validated, no validation error, before submit
    this.onValidateBeforeSubmit = onValidateBeforeSubmit || noopFn;
    this.submit = submit;
    this.isDisabled = false;
  }

  setError(field, message) {
    this.setState(prevState => ({
      ...prevState,
      ...setError(field, message)(prevState),
    }));
  }

  setErrors(errors, cb = noopFn) {
    this.setState(prevState => ({
      ...prevState,
      errors,
    }), () => cb(errors));
  }

  setValue = (field, value) => {
    this.setState(prevState => ({
      ...prevState,
      ...setStateVal(field, value, prevState),
    }));
  }

  manageValueArray = (field, add, value) => {
    this.setState(prevState => ({
      ...prevState,
      ...setStateArray(field, add, value, prevState),
    }));
  }

  onChange = (e) => {
    const field = e.target.name;
    const { value } = e.target;
    this.setValue(field, value);
  }

  onValidate = async (e, ctx = {}, translate = true) => {
    const field = e.target.name;
    if (!this.validations[field]) return;
    const { value } = e.target;
    const error = await this.validateField(field, value, ctx, translate);
    this.setError(field, error || '');
  }

  onChangeValue = (value, e) => {
    const field = e.target.name;
    this.setValue(field, value);
  }

  onValidateValue = async (value, e, ctx = {}, translate = true) => {
    const field = e.target.name;
    if (!this.validations[field]) return;
    const error = await this.validateField(field, value, ctx, translate);
    this.setError(field, error || '');
  }

  onChangeBoolean = (e) => {
    const field = e.target.name;
    const { value } = e.target;
    this.setValue(field, Boolean(value));
  }

  onValidateBoolean = async (e, ctx = {}, translate = true) => {
    const field = e.target.name;
    if (!this.validations[field]) return;
    const { value } = e.target;
    const error = await this.validateField(field, Boolean(value), ctx, translate);
    this.setError(field, error || '');
  }

  // Checkbox values are array state
  onChangeCheckbox = (e) => {
    const { value, checked, name } = e.target;
    this.manageValueArray(name, checked, value);
  }

  onValidateCheckbox = async (e, ctx = {}) => {
    const { value, checked, name } = e.target;
    if (!this.validations[name]) return;
    const error = await this.validateField(name, value, {
      ...ctx,
      checked,
    });
    this.setError(name, error || '');
  }

  validateField = async (field, value, ctx = {}) => {
    const rule = this.validations[field];
    if (!rule) return false;
    if (Array.isArray(rule)) {
      const error = rule.reduce((acc, _rule) => {
        if (acc) return acc;
        return _rule(value, ctx);
      }, false);
      if (error) {
        const resolved = await error;
        return resolved;
      }
      return false;
    }
    const error = await rule(value, ctx);
    if (error) {
      return error;
    }
    return false;
  }

  validateAll = async (values, ctx, translate) => {
    const { fields } = this;
    const errors = fields.reduce((acc, field) => {
      const rule = this.validations[field];
      if (!rule) return acc;
      const value = values[field];
      const error = this.validateField(field, value, {
        values,
        ...(ctx || {}),
      }, translate);
      if (error) {
        return { resolve: [...acc.resolve, error], fields: [...acc.fields, field] };
      }
      return acc;
    }, { resolve: [], fields: [] });

    if (errors.resolve.length === 0) return false;
    const resolved = await Promise.all([...errors.resolve]);
    const result = resolved
      .reduce((acc, r, i) => {
        if (!r) return acc;
        return {
          ...acc,
          [errors.fields[i]]: r,
        };
      }, {});

    return Object.keys(result).length > 0 ? result : false;
  }

  async validate(values, ctx, translate) {
    if (Object.keys(values).length === 0) {
      return false;
    }
    try {
      const errors = await this.validateAll(values, ctx, translate);
      if (errors) {
        return errors;
      }
      return false;
    } catch (error) {
      return { __default__: 'Unexpected error while validating' };
    }
  }

  // options, event or only event arguments
  handleSubmit = async (...args) => {
    const [eventOrOptions, event] = args;
    const ev = event || eventOrOptions;
    const options = event ? eventOrOptions : {};
    const { values = {}, ctx = {}, translate = true } = options;
    ev.preventDefault();

    // reset all errors
    this.setErrors({});
    // validate all fields
    const errors = await this.validate(values, ctx, translate);
    // stop on error
    if (errors) {
      this.setErrors(errors, (_errors) => { this.onValidateErrors(_errors, ev); });
      return;
    }

    if (await this.onValidateBeforeSubmit(ev)) {
      return;
    }

    this.setState((prevState) => {
      if (prevState.loading) {
        this.isDisabled = true;
      }
      return { loading: true };
    }, () => {
      if (this.isDisabled) {
        return;
      }
      this.submit(ev)
        .then((res) => {
          this.isDisabled = false;
          this.setState(
            () => ({ loading: false, errors: {} }),
            () => this.onSuccess(res, ev)
          );
        })
        .catch((e) => {
          this.isDisabled = false;
          this.setState(prevState => ({
            ...handleGraphQLErrors(e, prevState),
            loading: false,
          }), () => this.onError(e, ev));
        });
    });
  }
}

const Form = ({ children, ...props }) => (<form {...props}>{children}</form>);

export default Form;
