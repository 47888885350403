import React from 'react';
import styles from './index.scss';

const Loader = ({ children, loading, backgroundColor, padding }) => (
  <div
    className={[styles.loader, !loading ? styles.loading : null].join(' ')}
    style={padding ? { padding } : {}}
  >
    {!loading ? children : null}
    <div className={styles.holder} style={backgroundColor ? { backgroundColor } : {}}>
      <div className={styles.spinner} />
    </div>
  </div>
);

export default Loader;
