export default {
  Mutation: {
    setClientsPageInfo(_, { info }, { cache }) {
      try {
        cache.writeData({ data: { clientsPageInfo: info }, __typename: 'ClientsPageInfo' });
      } catch (e) {
        console.log('error', e);
      }

      return null;
    },
  },
  defaults: {
    clientsPageInfo: null,
  },
};
