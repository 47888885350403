export default {
  Mutation: {
    updateAuth(_, { loggedIn, user: userData }, { cache }) {
      const { __typename, id, ...user } = userData || {};
      if (!loggedIn) {
        cache.writeData({
          data: {
            auth: {
              __typename: 'LocalSession',
              loggedIn: false,
              user: null,
            },
          },
        });
        return null;
      }
      user.userId = id;
      user.__typename = 'CurrentUser';

      const data = {
        auth: {
          __typename: 'LocalSession',
          loggedIn,
          user: userData ? user : null,
        },
      };
      cache.writeData({ data });
      return null;
    },
  },
  defaults: {
    // Application local authorization data
    auth: {
      __typename: 'LocalSession',
      loggedIn: false,
      user: null,
    },
  },
};
