exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".wrapper_IJm{display:flex;flex-direction:column;align-items:center;justify-content:center;padding-top:100px;margin:0 auto}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/NotFound/src/components/NotFound/index.scss"],"names":[],"mappings":"AAAA,aACE,aACA,sBACA,mBACA,uBACA,kBACA,aAAc,CACf","file":"index.scss","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-top: 100px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "wrapper_IJm"
};