import React from 'react';
import ScreenErrors from 'components/ScreenErrors';
import Loader from 'components/Loader';
import { AppInfoFull } from 'components/App';
import withAppInfo from '../app/enhancers';
import styles from './index.scss';

const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { app } = data || {};

  return (
    <div className={styles.loadingHolder}>
      <Loader loading={loading} backgroundColor="#fff" padding="20px">
        <AppInfoFull data={app} />
      </Loader>
    </div>
  );
};

export default withAppInfo(Results);
