import React from 'react';
import { withRouterHistory } from 'utils/compose';
import { createMarkup } from 'utils/misc';
import Button from 'components/Button';
import styles from './index.scss';

const ScreenErrors = ({ errors, history, goBackButton }) => {
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.screenErrors}>
      {
        errors.map(error => (
          <p key={error} dangerouslySetInnerHTML={createMarkup(error)} /> // eslint-disable-line
        ))
      }

      {
        goBackButton
          && (
            <Button
              type="submit"
              onClick={() => handleGoBack()}
              success
            >
              {'Go Back'}
            </Button>
          )
      }
    </div>
  );
};

export default withRouterHistory(ScreenErrors);
