exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".searchScreen_2Uc{display:flex;flex-direction:column;align-items:center;position:absolute;top:0;left:0;z-index:20;padding:40px;width:100vw;height:100vh}.searchScreen_2Uc h2{font-weight:300;text-align:center;margin-bottom:4px;color:#47689f;font-size:24px}.searchScreen_2Uc h3{margin:0;font-size:14px;font-weight:300;text-align:center}.searchScreen_2Uc h3+div{margin-top:40px}.loadingMessage_3gL{display:flex;align-items:center;justify-content:center;padding:10px;text-align:center;color:rgba(255,255,255,0.6);background:#333c48}.loadingDone_1Cd{padding:40px 10px;text-align:center}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/search/src/modules/search/index.scss"],"names":[],"mappings":"AAAA,kBACE,aACA,sBACA,mBACA,kBACA,MACA,OACA,WACA,aACA,YACA,YAAa,CAVf,qBAaI,gBACA,kBACA,kBACA,cACA,cAAe,CAjBnB,qBAqBI,SACA,eACA,gBACA,iBAAkB,CAxBtB,yBA2BM,eAAgB,CACjB,oBAKH,aACA,mBACA,uBACA,aACA,kBACA,4BACA,kBAAmB,CACpB,iBAGC,kBACA,iBAAkB,CACnB","file":"index.scss","sourcesContent":[".searchScreen {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 20;\n  padding: 40px;\n  width: 100vw;\n  height: 100vh;\n\n  h2 {\n    font-weight: 300;\n    text-align: center;\n    margin-bottom: 4px;\n    color: #47689f;\n    font-size: 24px;\n  }\n\n  h3 {\n    margin: 0;\n    font-size: 14px;\n    font-weight: 300;\n    text-align: center;\n\n    + div {\n      margin-top: 40px;\n    }\n  }\n}\n\n.loadingMessage {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10px;\n  text-align: center;\n  color: rgba(#fff, .6);\n  background: #333c48;\n}\n\n.loadingDone {\n  padding: 40px 10px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"searchScreen": "searchScreen_2Uc",
	"loadingMessage": "loadingMessage_3gL",
	"loadingDone": "loadingDone_1Cd"
};