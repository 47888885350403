exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".hr_1J7{height:1px;margin:10px;border-bottom:1px dotted #caced7}.screenErrors_2XO{display:flex;flex-direction:column;align-items:center;justify-content:center;position:absolute;top:0;left:0;z-index:20;width:100vw;height:100vh}.screenErrors_2XO p{margin-bottom:20px}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/ScreenErrors/src/assets/css/_globals.scss","/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/ScreenErrors/src/components/ScreenErrors/index.scss"],"names":[],"mappings":"AAwCA,QACE,WACA,YACA,gCAAiC,CCzCnC,kBACE,aACA,sBACA,mBACA,uBACA,kBACA,MACA,OACA,WACA,YACA,YAAa,CAVf,oBAaI,kBAAmB,CACpB","file":"index.scss","sourcesContent":["$fontFamily: 'Ubuntu', sans-serif;\n\n$bg: #f1f4f8;\n$borderColor: #EAEDF3;\n$borderColorDarker: #D8DCE6;\n\n\n$textMain: #3E3F42;\n$textBase: #6B6C6F;\n$textSecondary: #9EA0A5;\n\n\n$green: #38B249;\n$blue: #0092ff;\n$orange: #F6AB2F;\n$red: #E6492D;\n\n$success: #38B249;\n$primary: #00a9ff;\n$warning: #F6AB2F;\n$error: #E6492D;\n\n$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);\n\n\n@mixin user-noselect {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n@mixin ellipsis() {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.hr {\n  height: 1px;\n  margin: 10px;\n  border-bottom: 1px dotted #caced7;\n}\n","@import '~assets/css/_globals.scss';\n\n.screenErrors {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 20;\n  width: 100vw;\n  height: 100vh;\n\n  p {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"hr": "hr_1J7",
	"screenErrors": "screenErrors_2XO"
};