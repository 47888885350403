exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".gauge_NNy{position:relative;width:160px;padding:20px 0}.min_1g2{position:absolute;bottom:16px;left:50%;margin-left:-54px;text-align:right;font-size:10px;font-weight:400}.max_1bY{position:absolute;bottom:16px;left:50%;margin-left:44px;text-align:left;font-size:10px;font-weight:400}.value_Oaz{position:absolute;bottom:16px;left:50%;transform:translateX(-50%);font-size:10px;font-weight:400}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/ticketing/snapshot/components/Gauge/src/modules/ticketing/snapshot/components/Gauge/index.scss"],"names":[],"mappings":"AAAA,WACE,kBACA,YACA,cAAe,CAChB,SAGC,kBACA,YACA,SACA,kBACA,iBACA,eACA,eAAgB,CACjB,SAGC,kBACA,YACA,SACA,iBACA,gBACA,eACA,eAAgB,CACjB,WAGC,kBACA,YACA,SACA,2BACA,eACA,eAAgB,CACjB","file":"index.scss","sourcesContent":[".gauge {\n  position: relative;\n  width: 160px;\n  padding: 20px 0;\n}\n\n.min {\n  position: absolute;\n  bottom: 16px;\n  left: 50%;\n  margin-left: -54px;\n  text-align: right;\n  font-size: 10px;\n  font-weight: 400;\n}\n\n.max {\n  position: absolute;\n  bottom: 16px;\n  left: 50%;\n  margin-left: 44px;\n  text-align: left;\n  font-size: 10px;\n  font-weight: 400;\n}\n\n.value {\n  position: absolute;\n  bottom: 16px;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: 10px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"gauge": "gauge_NNy",
	"min": "min_1g2",
	"max": "max_1bY",
	"value": "value_Oaz"
};