import { graphql } from 'react-apollo';
import { queryErrorsToArray } from 'utils/misc';
import { GetAppsPageInfo, SetAppsPageInfo } from './graphql/local.graphql';

import { SearchApps } from './graphql/search.graphql';

export const withAppsPageInfo = graphql(GetAppsPageInfo, {
  props: (props) => {
    const { data: { appsPageInfo, error } } = props;
    return {
      error,
      pageInfo: appsPageInfo,
    };
  },
});

const setInfo = mutate => info => mutate({
  variables: { info },
});

export const withSetAppsPageInfo = graphql(SetAppsPageInfo, {
  props: ({ mutate }) => ({ setInfo: setInfo(mutate) }),
});

export const withSearchApps = graphql(SearchApps, {
  props: ({ data: { viewer, error, loading, fetchMore } }) => ({
    errors: queryErrorsToArray(error),
    loading,
    data: viewer,
    fetchMore,
  }),
  options: props => ({
    variables: {
      input: {
        type: props.type,
        term: props.term,
        after: props.after,
        before: props.before,
        first: props.first,
        last: props.last,
      },
    },
  }),
});
