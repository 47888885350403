import React, { Component } from 'react';
import Input from 'components/Input';

import styles from './index.scss';

class SearchForm extends Component {
  state = {
    searchTerm: '',
  }

  inputRef = React.createRef()

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focus();
    }
  }

  focus = () => {
    this.inputRef.current.customFocus();
  }

  onChange = (ev) => {
    ev.preventDefault();
    ev.persist();

    this.setState({
      searchTerm: ev.target.value,
    });
  }

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const { searchTerm } = this.state;
      const { onSubmit } = this.props;
      onSubmit(searchTerm || '');
    }
  }

  render() {
    const { searchTerm } = this.state;
    const { placeholder, type, autoFocus } = this.props;

    return (
      <div className={styles.searchForm}>
        <Input
          ref={this.inputRef}
          autoFocus={autoFocus}
          name="label"
          placeholder={placeholder || ''}
          type="text"
          autoComplete={`search${type}`}
          value={searchTerm || ''}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
        />
      </div>
    );
  }
}


export default SearchForm;
