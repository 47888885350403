import React from 'react';
import Results from './Results';

const View = ({ match }) => {
  const { objectId } = match.params;

  return <Results objectId={objectId} />;
};

export default View;
