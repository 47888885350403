import React from 'react';
import { withRouterProps } from 'utils/compose';
import AppStatus from 'components/AppStatus';
import styles from './index.scss';

const AppList = ({ history, data }) => {
  const apps = data.map(({ node: app }) => {
    const { status } = app;

    const iconRender = () => {
      if (app.icon) {
        return (
          <img // eslint-disable-line
            src={`https://api-parseground.s3.amazonaws.com/${app.icon}`}
            alt={app.name}
            onClick={() => {
              history.push(`/app/${app.id}/info`);
            }}
          />
        );
      }

      return (
        <img // eslint-disable-line
          src="/assets/images/default_app_icon.png"
          alt={app.name}
          onClick={() => {
            history.push(`/app/${app.id}/info`);
          }}
        />
      );
    };

    return (
      <div className={styles.app} key={app.id}>
        {iconRender()}
        <div className={styles.region}>{app.region}</div>
        <div className={styles.status}>
          <AppStatus status={app.status} />
        </div>
        <div className={styles.appInfo}>
          <p className={styles.name}>{app.name}</p>
          <p className={styles.serviceName}>{app.serviceName}</p>
        </div>
      </div>
    );
  });

  let gridFixStyle;
  if (apps.length < 5) {
    gridFixStyle = { gridTemplateColumns: `repeat(${apps.length}, 240px)` };
  }

  return (
    <div className={styles.appListWrapper}>
      <div className={styles.appList} style={gridFixStyle}>
        {apps}
      </div>
    </div>
  );
};

export default withRouterProps(AppList);
