import React from 'react';
import classnames from 'classnames';
import styles from './index.scss';

const TableELD = ({ min, max, avg, total }) => {
  const getColor = (v) => {
    const percent = (v / total);
    let color = 'c0';
    if (percent > 0.25 && percent < 0.50) {
      color = 'c1';
    } else if (percent >= 0.5 && percent < 0.75) {
      color = 'c2';
    } else if (percent >= 0.75) {
      color = 'c3';
    }

    return color;
  };

  return (
    <div className={styles.smallTable}>
      <span className={classnames(styles.triangle)} />
      <div className={styles.box}>
        <div className={styles.row}>
          <span>min</span>
          <span>max</span>
          <span>avg</span>
        </div>
        <div className={styles.row}>
          <span className={classnames(styles.bg, styles[getColor(min)])}>
            {min.toFixed(2)}
            ms
          </span>
          <span className={classnames(styles.bg, styles[getColor(max)])}>
            {max.toFixed(2)}
            ms
          </span>
          <span className={classnames(styles.bg, styles[getColor(avg)])}>
            {avg.toFixed(2)}
            ms
          </span>
        </div>
      </div>
    </div>
  );
};

export default TableELD;
