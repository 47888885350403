import React, { Component } from 'react';
import ScreenErrors from 'components/ScreenErrors';
import { compose, withRouterProps } from 'utils/compose';
import InfiniteScroll from 'react-infinite-scroll-component';
import Gravatar from 'react-gravatar';
import { withActivityLog } from './enhancers';
import List from './list';

import styles from './index.scss';


class Results extends Component {
  loadMore = () => {
    const {
      fetchMore,
      data,
      type,
      before,
      first,
      last,
    } = this.props;

    const { pageInfo } = data.client.activityLog;
    const { endCursor, hasNextPage } = pageInfo;

    if (!hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          type,
          after: endCursor,
          before,
          first,
          last,
        },
      },
      updateQuery: (oldData, { fetchMoreResult: newData }) => {
        const result = {
          viewer: {
            client: {
              ...oldData.viewer.client,
              __typename: oldData.viewer.client.__typename,
              activityLog: {
                __typename: oldData.viewer.client.activityLog.__typename,
              },
            },
            __typename: oldData.viewer.__typename,
          },
        };

        result.viewer.client.activityLog.pageInfo = newData.viewer.client.activityLog.pageInfo;
        result.viewer.client.activityLog.edges = [
          ...oldData.viewer.client.activityLog.edges,
          ...newData.viewer.client.activityLog.edges,
        ];

        // console.log('oldData', oldData);
        // console.log('newData', newData);

        return result;
      },
    });
  }

  render() {
    const {
      errors,
      loading,
      data,
      history,
    } = this.props;

    if (loading) return null;

    if (errors.length > 0) {
      return (
        <ScreenErrors errors={errors} />
      );
    }

    const { pageInfo, edges: results } = data.client.activityLog;

    if (results.length === 0) {
      return (
        <ScreenErrors errors={['There is no activity log for this user']} />
      );
    }

    const { hasNextPage } = pageInfo;

    const endMessage = hasNextPage
      ? null
      : <p className={styles.loadingDone}>Yay! You have seen it all</p>;

    if (results.length === 1) {
      const { id } = results[0].node;
      // prevent react warning: Cannot update during an existing state transition
      setTimeout(() => {
        history.replace(`/client/${id}/info`);
      }, 50);

      return null;
    }

    const imageURL = () => {
      if (data.client.image) {
        return `https://api-parseground.s3.amazonaws.com/${data.client.image}`;
      }

      return '/assets/images/avatar.png';
    };

    return (
      <div>
        <div className={styles.activityLog}>
          {
            data.client.useGravatar
              ? <Gravatar email={data.client.email} size={60} default="mm" />
              : <img src={imageURL()} alt={data.client.email} />
          }
          <h2>{data.client.email}</h2>
        </div>
        <InfiniteScroll
          dataLength={results.length} // This is important field to render the next data
          next={this.loadMore}
          hasMore={hasNextPage}
          height={window.innerHeight - 70}
          loader={(
            <p className={styles.loadingMessage}>Loading ...</p>
          )}
          endMessage={endMessage}
        >
          <List data={results} />
        </InfiniteScroll>
      </div>
    );
  }
}

export default compose(
  withRouterProps,
  withActivityLog
)(Results);
