import React from 'react';
import Gauge from '../Gauge';
import styles from './index.scss';

// import Table from './table';
import TableCPU from './TableCPU';
import TableRAM from './TableRAM';
import TableELD from './TableELD';

const mbConverter = (mb) => {
  let bytes;
  if (mb.includes('M')) {
    const m = Number(mb.replace('M', ''));
    bytes = m * 1024 * 1024;
  } else if (mb.includes('G', '')) {
    const m = Number(mb.replace('G'));
    bytes = m * 1024 * 1024 * 1024;
  }

  return bytes;
};

const formatBytes = (v) => {
  let bytes = v;
  const sizes = ['B', 'K', 'M', 'G', 'T', 'P'];
  for (let i = 0; i < sizes.length; i++) {
    if (bytes <= 1000) {
      return bytes;
    }

    bytes = parseFloat(bytes / 1000).toFixed(2);
  }
  return bytes;
};

const ProcessInfoAverages = ({ data, lastDeploy }) => {
  if (!data || !lastDeploy) return null;

  const {
    api,
    worker,
  } = data;

  const renderApi = () => {
    const {
      restarts,
      cpu,
      ram,
      event_loop_delay: eld,
    } = api || {};

    if (!cpu || !ram || !eld) return null;

    const limitRam = mbConverter(lastDeploy.limits.limitRAM);

    return (
      <tr>
        <td>
          <div className={styles.info}>
            <h3 className={styles.title}>
              API
            </h3>
            restarts (
              {restarts}
            )
          </div>
        </td>
        <td>
          <TableCPU
            min={cpu.min}
            max={cpu.max}
            avg={cpu.avg}
          />
        </td>
        <td>
          <TableRAM
            min={ram.min}
            max={ram.max}
            avg={ram.avg}
            total={limitRam}
          />
        </td>
        <td>
          <TableELD
            min={eld.min}
            max={eld.max}
            avg={eld.avg}
            total={100}
          />
        </td>
      </tr>
    );
  };

  const renderWorker = () => {
    const {
      cpu,
      ram,
      event_loop_delay: eld,
      restarts,
    } = worker || {};

    if (!cpu || !ram || !eld) return null;

    const cpuPercent = (((cpu.avg - cpu.min) * 100) / (cpu.max - cpu.min)) / 100;
    const ramPercent = (((ram.avg - ram.min) * 100) / (ram.max - ram.min)) / 100;
    const eldPercent = (((eld.avg - eld.min) * 100) / (eld.max - eld.min)) / 100;

    return (
      <tr>
        <td>
          <div className={styles.info}>
            <h3 className={styles.title}>
            WORKER
            </h3>
            restarts (
              {restarts}
            )
          </div>
        </td>
        <td>
          <Gauge
            id="worker-cpu"
            percent={cpuPercent.toFixed(2)}
            min={cpu.min.toFixed(2)}
            max={cpu.max.toFixed(2)}
            state="running"
            value={cpu.avg.toFixed(2)}
          />
        </td>
        <td>
          <Gauge
            id="worker-mem"
            percent={ramPercent.toFixed(2)}
            min={formatBytes(ram.min)}
            max={formatBytes(ram.max)}
            state="running"
            value={formatBytes(ram.avg)}
          />
        </td>
        <td>
          <Gauge
            id="worker-eld"
            percent={eldPercent.toFixed(2)}
            min={(eld.min).toFixed(2)}
            max={(eld.max).toFixed(2)}
            state="running"
            value={`${(eld.avg / 100).toFixed(2)}ms`}
          />
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.processInfoAverages}>
      <div className={styles.item}>
        <div className={styles.itemHeader}>
          <h4>Averages</h4>
        </div>
        <table className={[styles.table, styles.custom].join(' ')}>
          <thead>
            <tr>
              <th>Type</th>
              <th>CPU</th>
              <th>MEMORY</th>
              <th>ELD</th>
            </tr>
          </thead>
          <tbody>
            {renderApi()}
            {renderWorker()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProcessInfoAverages;
