import { graphql } from 'react-apollo';
import { withUpdateAuth, showWhenAuthorized, withAuth, redirectWhenNotAuthorized } from 'app/Auth';
import { compose, withProps } from 'utils/compose';
import Logout from './logout.graphql';
// import styles from './index.scss';

const logoutHandler = logout => async () => {
  try {
    await logout();
  } catch (e) {
    // console.log(e.message);
  }

  localStorage.removeItem('sessionToken');
  window.location = '/login';
};

const LogOutView = ({ logout }) => {
  logout();

  return null;
};

export default compose(
  redirectWhenNotAuthorized(),
  showWhenAuthorized,
  withAuth,
  withUpdateAuth,
  graphql(Logout, {
    props: ({ mutate }) => ({ logout: mutate }),
  }),
  withProps(({ logout }) => ({ logout: logoutHandler(logout) }))
)(LogOutView);
