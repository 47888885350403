import React from 'react';
import { showWhenAdmin } from 'app/Auth';
import Icon from 'components/Icon';

const urls = {
  '1-eu': 'https://console-1-eu-os.cloudstrap.io',
  '2-us': 'https://console-2-us-os.cloudstrap.io',
  '1-jp': 'https://console-1-jp-os.cloudstrap.io:8443',
  '1-au': 'https://console-1-au-os.cloudstrap.io:8443',
};

const openShiftURLs = ({ region, serviceName }) => [
    <a
      key="OpenShiftVeiw"
      href={`${urls[region]}/console/project/${serviceName}/overview`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="Openshift Console"
        src="/assets/images/logos/openshift.svg"
      />
      overview
    </a>,
    <a
      key="pods"
      href={`${urls[region]}/console/project/${serviceName}/browse/pods`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="Openshift Console"
        src="/assets/images/logos/openshift.svg"
      />
      pods
    </a>,
    <a
      key="deployment"
      href={`${urls[region]}/console/project/${serviceName}/browse/deployments/parse-server-deployment`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="Openshift Console"
        src="/assets/images/logos/openshift.svg"
      />
      deployment
    </a>,
    <a
      key="build"
      href={`${urls[region]}/console/project/${serviceName}/browse/builds/parse-server-buildconfig`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="Openshift Console"
        src="/assets/images/logos/openshift.svg"
      />
      build
    </a>,
    <a
      key="routes"
      href={`${urls[region]}/console/project/${serviceName}/browse/routes`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="Openshift Console"
        src="/assets/images/logos/openshift.svg"
      />
      routes
    </a>,
    <a
      key="github-repo"
      href={`https://github.com/parsegroundapps/pg-app-${serviceName}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="icon-github" width={20} height={20} />
      GitHub Repo
    </a>,
];


export default showWhenAdmin(openShiftURLs);
