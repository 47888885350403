import React from 'react';
import { Route } from 'react-router-dom';

import NotFound from 'components/NotFound';

import loading from './local.loading';


export default {
  routes: [
    <Route key="404" component={NotFound} />,
  ],
  Mutation: {
    ...loading.Mutation,
  },
  defaults: {
    ...loading.defaults,
  },
};
