import React from 'react';
import SearchResultsApps from './apps';
import SearchResultsClients from './clients';


const SearchResults = ({ match }) => {
  const { segment } = match.params;

  if (segment === 'app') {
    return <SearchResultsApps />;
  }

  if (segment === 'client') {
    return <SearchResultsClients />;
  }

  return null;
};

export default SearchResults;
