import React from 'react';
import { Route } from 'react-router-dom';

import View from './View';

export default {
  routes: [
    <Route key="ticketing" exact path="/ticketing/:ticketId" component={View} />,
  ],

  useAsIframe: ['/ticketing'],
};
