import { graphql } from 'react-apollo';

import { queryErrorsToArray } from 'utils/misc';
import { AppInfo } from './graphql/app.graphql';

const withAppInfo = graphql(AppInfo, {
  props: ({ data: { viewer, error, loading } }) => ({
    errors: queryErrorsToArray(error),
    loading,
    data: viewer,
  }),
  options: props => ({
    variables: {
      id: props.objectId,
    },
  }),
});

export default withAppInfo;
