import React, { Fragment } from 'react';
import classNames from 'classnames';
import ProgressPie from 'components/ProgressPie';
import Icon from 'components/Icon';
import Tabs from 'components/Tabs';
import CopyRow from 'components/CopyRow';

import ProcessInfoLatest from './components/ProcessInfoLatest';
import ProcessInfoAverages from './components/ProcessInfoAverages';
import Builds from './components/Builds';
import Deploys from './components/Deploys';
import HealthCheck from './components/HealthCheck';

import styles from './index.scss';

const snapshot = ({ ticket }) => {
  const { data } = ticket;

  const {
    general,
    billingInfo,
    healthCheck,
    runtime,
    processInfo,
  } = data;

  const {
    appName,
    region,
    serviceName,
    parseServerVersion,
    appStatus,
    cloudCode,
    isOwner,
  } = general;

  const {
    autoScaling,
    highlyAvailable,
    min: highlyAvailableMin,
    max: highlyAvailableMax,
    type: highlyAvailableType,
    activeEngines,
  } = runtime.engines;

  const lastDeploy = runtime.deploys[0];
  const lastBuild = runtime.builds[0];

  return (
    <div className={styles.sections}>
      <div className={styles.column}>
        <div className={styles.row}>
          <div className={styles.subColumn}>
            <div className={styles.col}>
              <h2>Runtime Info</h2>
              <ProgressPie
                className={styles.progressPie}
                disabled
                currentPods={activeEngines}
                autoscaling={autoScaling}
                highlyAvailable={highlyAvailable}
                min={highlyAvailableMin}
                max={highlyAvailableMax}
              />
            </div>
            <div className={styles.col} style={{ marginLeft: '20px', width: '100%' }}>
              <h2>Client Info</h2>

              <CopyRow data={cloudCode ? 'Is App Owner' : 'Is not App Owner'} className={styles.rowHighlight}>
                <div className={styles.row}>
                  <div className={styles.key}>Is App Owner</div>
                  <div className={styles.value}>
                  {
                    isOwner
                      ? <Icon name="icon-check" width={14} height={14} fill="#1bb933" />
                      : <Icon name="icon-close" width={14} height={10} fill="#fd6c53" />
                  }
                  </div>
                </div>
              </CopyRow>


              {
                isOwner
                  && (
                    <Fragment>
                      <CopyRow data={billingInfo.subscriptionStatus} className={styles.rowHighlight}>
                        <div className={styles.row}>
                          <div className={styles.key}>Subscription Status</div>
                          <div className={styles.value}>
                            {billingInfo.subscriptionStatus}
                          </div>
                        </div>
                      </CopyRow>

                      <CopyRow data={billingInfo.unpaidInvoices ? 'There are unpaid invoices' : 'There are no unpaid invoices'} className={styles.rowHighlight}>
                        <div className={styles.row}>
                          <div className={styles.key}>Unpaid Invoices</div>
                          <div className={styles.value}>
                          {
                            billingInfo.unpaidInvoices
                              ? <Icon name="icon-check" width={14} height={14} fill="#1bb933" />
                              : <Icon name="icon-close" width={14} height={10} fill="#fd6c53" />
                          }
                          </div>
                        </div>
                      </CopyRow>
                    </Fragment>
                  )
              }
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <h2>Application Info</h2>

          <CopyRow data={appName} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>App Name</div>
              <div className={styles.value}>
                <span>{appName}</span>
              </div>
            </div>
          </CopyRow>

          <CopyRow data={serviceName} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>Service Name</div>
              <div className={styles.value}>
                <span>{serviceName}</span>
              </div>
            </div>
          </CopyRow>

          <CopyRow data={region} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>Region</div>
              <div className={styles.value}>
                <span>{region}</span>
              </div>
            </div>
          </CopyRow>

          <CopyRow data={appStatus} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>App Status</div>
              <div className={styles.value}>
                <span>{appStatus}</span>
              </div>
            </div>
          </CopyRow>

          <CopyRow data={parseServerVersion} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>Parse Server Version</div>
              <div className={styles.value}>
                <span>{parseServerVersion}</span>
              </div>
            </div>
          </CopyRow>

          <CopyRow data={cloudCode ? 'Use Cloud Code' : 'Not use Cloud Code'} className={styles.rowHighlight}>
            <div className={styles.row}>
              <div className={styles.key}>Cloud Code</div>
              <div className={styles.value}>
                {
                  cloudCode
                    ? <Icon name="icon-check" width={14} height={14} fill="#1bb933" />
                    : <Icon name="icon-close" width={14} height={10} fill="#fd6c53" />
                }
              </div>
            </div>
          </CopyRow>
        </div>
      </div>


      {
        (processInfo && lastBuild && lastBuild.phase && lastDeploy && lastDeploy.phase)
          && (
            <div className={classNames(styles.column, styles.processInfo)}>
              <div className={styles.row}>
                <Tabs contentClassName={styles.tabHolder}>
                  {
                    processInfo.latest && lastDeploy
                    && (
                      <div label="Process Info">
                        <Fragment>
                          <ProcessInfoLatest data={processInfo.latest} lastDeploy={lastDeploy} />
                          <ProcessInfoAverages data={processInfo.averages} lastDeploy={lastDeploy} />
                        </Fragment>
                      </div>
                    )
                  }
                  { runtime.builds
                    && (
                      <div label="Builds" status={lastBuild.phase}>
                        <Builds data={runtime.builds} />
                      </div>
                    )
                  }
                  { runtime.deploys
                    && (
                      <div label="Deploys" status={lastDeploy.phase}>
                        <Deploys data={runtime.deploys} />
                      </div>
                    )
                  }

                  {
                    healthCheck && (
                      <div label="Health" status={healthCheck.statusCode === 200 ? 'Active' : 'Failed'}>
                        <HealthCheck data={healthCheck} />
                      </div>
                    )
                  }
                </Tabs>
              </div>
            </div>
          )
      }
    </div>
  );
};

export default snapshot;
