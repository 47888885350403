import React from 'react';
import { toDate } from 'utils/misc';
import Gravatar from 'react-gravatar';
import { Link } from 'react-router-dom';
import Row from 'components/Row';
import { showWhenAdmin } from 'app/Auth';
import Tabs from 'components/Tabs';
import VisibilityChangeHOC from 'components/VisibilityChangeHOC';
import AppStatus from 'components/AppStatus';
import AppEnginesInfo from 'components/AppEnginesInfo';
import DropDown from './components/DropDown';
import OpenShiftURLs from './components/openShiftURLs';

import styles from './index.scss';


const AppInfoFull = ({ data }) => {
  const appOwner = ({ client: { useGravatar, image, email, id } }) => {
    const imageURL = () => {
      if (image) {
        return `https://api-parseground.s3.amazonaws.com/${image}`;
      }

      return '/assets/images/avatar.png';
    };

    const path = `/client/${id}/info`;
    return (
      <Link
        to={path}
        className={styles.type}
      >
        {
          useGravatar && !image
            ? <Gravatar email={email} size={30} default="mm" />
            : <img src={imageURL()} alt={email} title={email} />
        }
        <span>{email}</span>
      </Link>
    );
  };

  const openShiftView = () => (
    <div className={styles.openshift}>
      <OpenShiftURLs region={data.region} serviceName={data.serviceName} />
    </div>
  );

  const rows = () => {
    const AdminOnly = showWhenAdmin(() => [
      <Row key="masterKey" title="Master Key" value={data.masterKey} />,
      <Row key="clientKey" title="Client Key" value={data.clientKey} />,
      <Row key="javascriptKey" title="JavaScript Key" value={data.javascriptKey} />,
      <Row key="dotNetKey" title="DotNet Key" value={data.dotNetKey} />,
      <Row key="restAPIKey" title="Rest API Key" value={data.restAPIKey} />,
      <Row key="webhookKey" title="Webhook Key" value={data.webhookKey} />,
      <Row key="fileKey" title="File Key" value={data.fileKey} />,
      <Row key="s3_bucket" title="S3 Bucket" value={data.s3_bucket} />,
      <Row key="databaseRegion" title="Database Region" value={data.databaseRegion} />,
      <Row key="databaseUri" title="Database Connection String" value={data.databaseUri} />,
    ]);

    const result = [
      <Row key="parseServerVersion" title="Parse Server Version" value={data.parseServerVersion} />,
      <Row key="hasRedis" title="Redis" value={data.appSettings.hasRedis} />,
      <Row key="createdAt" title="CreatedAt" value={toDate(data.createdAt)} />,
      <Row key="serviceName" title="Service Name" value={data.serviceName} />,
      <Row key="appId" title="App Id" value={data.appId} />,
      <Row key="serverUrl" title="Server URL" value={data.serverUrl} />,
      <AdminOnly key="AdminOnly" />,
    ];

    return result;
  };

  const appSettings = () => {
    const {
      requireRevocableSession,
      clientClassCreationEnabled,
      enableBackups,
      clientPushEnabled,
      restPushEnabled,
      expireRevocableSession,
      revokeOnPasswordReset,
      verifyEmails,
      hostName,
      letsEncrypt,
      customSenderEmail,
      liveQueriesClasses,
      gcmCredentials,
    } = data.appSettings;

    const result = [
      <Row key="requireRevocableSession" title="Require Revocable Session" value={requireRevocableSession} />,
      <Row key="clientClassCreationEnabled" title="Client Class Creation Enabled" value={clientClassCreationEnabled} />,
      <Row key="enableBackups" title="Backups Enabled" value={enableBackups} />,
      <Row key="clientPushEnabled" title="Client Push Enabled" value={clientPushEnabled} />,
      <Row key="restPushEnabled" title="Rest Push Enabled" value={restPushEnabled} />,
      <Row key="expireRevocableSession" title="Expire Revocable Session" value={expireRevocableSession} />,
      <Row key="revokeOnPasswordReset" title="Revoke on Password Reset" value={revokeOnPasswordReset} />,
      <Row key="verifyEmails" title="Verify Emails" value={verifyEmails} />,
      <Row key="hostName" title="Host Name" value={hostName} />,
      <Row key="customSenderEmail" title="Custom Sender Email" value={customSenderEmail} />,
      <Row key="letsEncrypt" title="Let's Encrypt" value={letsEncrypt} />,
      <Row
        key="liveQueriesClasses"
        title="Live Queries Classes"
        value={liveQueriesClasses ? liveQueriesClasses.toString() : ''}
      />,
    ];

    if (gcmCredentials && gcmCredentials.length > 0) {
      gcmCredentials.forEach((item) => {
        result.push(
          <Row key={item.senderId} title="GCM/FCM Sender Id" value={item.senderId} />
        );
        result.push(
          <Row key={item.apiKey} title="GCM/FCM API Key" value={item.apiKey} />
        );
      });
    }

    return result;
  };

  return (
    <div className={styles.app} key={data.objectId}>
      <div className={styles.head}>
        <div className={styles.iconHolder}>
          <AppStatus status={data.status} />
          <img
            alt={data.name}
            src={data.icon ? `https://api-parseground.s3.amazonaws.com/${data.icon}` : '/assets/images/default_app_icon.png'}
            className={styles.appIcon}
          />
        </div>
        <div className={styles.appInfo}>
          <h3>
            {data.name}
          </h3>
          {appOwner(data)}
        </div>

        <DropDown
          collaborators={data.appSettings.collaborators}
          ownerId={data.client.id}
          appId={data.id}
        >
          Collaborators
        </DropDown>

        <span
          className={styles.region}
          key="RegionView"
        >
          {data.region}
        </span>
      </div>
      <br />
      <div className={styles.details}>
        {openShiftView()}

        <VisibilityChangeHOC>
          <AppEnginesInfo appId={data.id} />
        </VisibilityChangeHOC>

        <Tabs>
          <div label="Info">
            {rows()}
          </div>
          <div label="Settings">
            {appSettings()}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default AppInfoFull;
