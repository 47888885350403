import React, { Component } from 'react';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class VisibilityChangeHOC extends Component {
  state = {
    isOnFocus: true,
  }

  componentDidMount() {
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document[hidden]) {
      this.setState({ isOnFocus: false });
    } else {
      this.setState({ isOnFocus: true });
    }
  }

  render() {
    const { isOnFocus } = this.state;
    const { children } = this.props;


    return isOnFocus ? children : <div>Loading ...</div>;
  }
}

export default VisibilityChangeHOC;
