import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import View from './View';
import Activity from './Activity';

export default {
  routes: [
    <Redirect key="clientInfoRedirect" exact from="/client/:objectId" to="/client/:objectId/info" />,
    <Route key="clientInfo" exact path="/client/:objectId/info" component={View} />,
    <Route key="clientActivity" exact path="/client/:objectId/activity" component={Activity} />,
  ],
};
