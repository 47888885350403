import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import { TooltipPortalProvider } from 'react-autotip';

import AuthChecker from 'app/Auth/Checker';
import ConfigChecker from 'app/ConfigChecker';
import ScreenLoading, { withScreenLoading } from 'app/ScreenLoading';
import { withRouterHistory, compose } from 'utils/compose';
import client from 'utils/apollo';
import modules from 'modules';

import LayoutView from 'components/Layout';

const Layout = compose(
  withScreenLoading,
  withRouterHistory
)(LayoutView);

const { routes, topRightBar, topCenterBar, useAsIframe } = modules;

const App = () => (
  <ApolloProvider client={client}>
    <TooltipPortalProvider>
      <AuthChecker>
        <Router>
          <ConfigChecker>
            <Layout topRightBar={topRightBar} topCenterBar={topCenterBar} useAsIframe={useAsIframe}>
              <Switch>{routes}</Switch>
            </Layout>
            <ScreenLoading />
          </ConfigChecker>
        </Router>
      </AuthChecker>
      <Isvg src="/assets/images/sprite.svg" uniquifyIDs={false} />
    </TooltipPortalProvider>
  </ApolloProvider>
);

export default App;
