import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouterHistory } from 'utils/compose';

import styles from './index.scss';

const Header = ({ topCenterBar, topRightBar }) => (
    <div className={styles.header}>
      <div className={styles.logos}>
        <Link to="/" tabIndex="-1">
          <img src="/assets/images/logo.svg" alt="SashiDo Support Tool" />
          <p>Powered by CloudStrap</p>
        </Link>
      </div>

      <div className={styles.topBarItemsCenter}>
        {topCenterBar.length > 0 && topCenterBar.map(item => (
          <Fragment key={`item-${item.key}`}>{item}</Fragment>
        ))}
      </div>

      <div className={styles.topBarItemsRight}>
        {topRightBar.length > 0 && topRightBar.map(item => (
          <Fragment key={`item-${item.key}`}>{item}</Fragment>
        ))}
      </div>
    </div>
);
export default withRouterHistory(Header);
