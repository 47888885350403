import React from 'react';
import { Link } from 'react-router-dom';

import { redirectWhenNotAuthorized } from 'app/Auth';
import Form from './components/Form';

import styles from './index.scss';

const SearchView = ({ history }) => {
  const onClientSearch = (v) => {
    history.push(`/search/client/auto/${v}`);
  };

  const onAppSearch = (v) => {
    history.push(`/search/app/auto/${v}`);
  };

  return (
    <div className={styles.searchScreen}>
      <h2>Search</h2>
      <Form placeholder="Find client by username, email or pin" type="Client" onSubmit={onClientSearch} autoFocus />
      <Form placeholder="Find application by name or serviceName" type="App" onSubmit={onAppSearch} />
    </div>
  );
};

export default redirectWhenNotAuthorized()(SearchView);
