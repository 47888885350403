import React from 'react';
import { Route } from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';
import TopBarUserProfile from './TopBarUserProfile';

import loginLocal from './Login/local';
import local from './local';

export default {
  routes: [
    <Route key="login" exact path="/login" component={Login} />,
    <Route key="logout" exact path="/logout" component={Logout} />,
  ],
  topRightBar: [
    <TopBarUserProfile key="userProfile" />,
  ],
  Mutation: {
    ...local.Mutation,
    ...loginLocal.Mutation,
  },
  defaults: {
    ...local.defaults,
    ...loginLocal.defaults,
  },
};
