import { graphql } from 'react-apollo';
import { queryErrorsToArray } from 'utils/misc';
import { GetClientPageInfo, SetClientPageInfo } from './graphql/local.graphql';

import { SearchClients } from './graphql/search.graphql';

export const withClientsPageInfo = graphql(GetClientPageInfo, {
  props: (props) => {
    const { data: { clientsPageInfo, error } } = props;
    return {
      error,
      pageInfo: clientsPageInfo,
    };
  },
});

const setInfo = mutate => info => mutate({
  variables: { info },
});

export const withSetClientsPageInfo = graphql(SetClientPageInfo, {
  props: ({ mutate }) => ({ setInfo: setInfo(mutate) }),
});

export const withSearchClients = graphql(SearchClients, {
  props: ({ data: { viewer, error, loading, fetchMore } }) => ({
    errors: queryErrorsToArray(error),
    loading,
    data: viewer,
    fetchMore,
  }),
  options: props => ({
    variables: {
      input: {
        type: props.type,
        term: props.term,
        after: props.after,
        before: props.before,
        first: props.first,
        last: props.last,
      },
    },
  }),
});
