import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';

import { compose } from 'utils/compose';

import { withManageScreenLoading } from 'app/ScreenLoading';
import { withUpdateAuth } from './index';
import CurrentUser from './graphql/current.user.graphql';

/**
 * Detect user session once on mounting.
 * Validate remote session and sync it with the local session data.
 * The component should be at the top of App hierarchy,
 * just after apollo and router providers.
 */
class AuthChecker extends PureComponent {
  async componentDidMount() {
    // Disable loading screen if no query will be made
    if (!localStorage.getItem('sessionToken')) {
      const { resetLoading } = this.props;
      await resetLoading();
    }
    // Universal app - we can get sessionToken from httpOnly cookie
    // here and add/replace localStorage key. Query `skip` options
    // should be adapted as well.
  }

  async componentDidUpdate(prevProps) {
    const { loading: propsLoading, startLoading, stopLoading } = this.props;

    // start loading - query is executed
    if (prevProps.loading && !propsLoading) {
      const { setSession, viewer, sessionError } = this.props;
      await startLoading();

      if (sessionError && sessionError !== 'Network error: Failed to fetch') {
        setSession(false);
        localStorage.removeItem('sessionToken');
      } else {
        setSession(true, viewer);
      }

      await stopLoading();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}


const withAuthChecker = compose(
  withUpdateAuth,
  withManageScreenLoading,
  graphql(CurrentUser, {
    skip: () => !localStorage.getItem('sessionToken'),
    options: { pollInterval: 0, fetchPolicy: 'no-cache' },
    props: ({ data: { viewer, error, loading } }) => {
      // Hide the uglyness!!!
      let message = null;
      if (error) {
        const { networkError } = error;

        // console.log('networkError', networkError);
        // console.log('error', error);

        if (!networkError) {
          return { loading, sessionError: error.message, viewer };
        }

        const { result = {} } = networkError;
        const [err] = result.errors || [];
        message = err ? err.message : error.message;
      }
      return { loading, sessionError: message, viewer };
    },
  })
);

export default withAuthChecker(AuthChecker);
