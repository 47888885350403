import React, { Fragment } from 'react';
import GaugeChart from 'react-gauge-chart';
import styles from './index.scss';

const Gauge = ({ id, percent, min = 0, max, value = '', state }) => {
  const [c0, c1, c2, c3] = ['#1bb933', '#f6dc2f', '#F6AB2F', '#E6492D'];
  let color = c0;
  let needleColor = '#999';
  let needleBaseColor = '#555';

  const showItems = state.toLowerCase() === 'running';

  if (percent > 0.25 && percent < 0.50) {
    color = c1;
  } else if (percent >= 0.5 && percent < 0.75) {
    color = c2;
  } else if (percent >= 0.75) {
    color = c3;
  } else if (!showItems) {
    color = '#ededed';
    needleColor = '#ededed';
    needleBaseColor = '#ededed';
  }

  return (
    <div className={styles.gauge}>
      <GaugeChart
        id={`gauge-${id}`}
        textColor="#333"
        colors={[color, color, color, color]}
        nrOfLevels={1}
        hideText
        needleColor={needleColor}
        needleBaseColor={needleBaseColor}
        arcPadding={0.1}
        cornerRadius={4}
        percent={Number(percent)}
      />
      {
        showItems
          && (
            <Fragment>
              <div className={styles.min}>{min}</div>
              <div className={styles.value}>{value}</div>
              <div className={styles.max}>{max}</div>
            </Fragment>
          )
      }
    </div>
  );
};

export default Gauge;
