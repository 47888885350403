import appsLocal from './apps/local';
import clientsLocal from './clients/local';

export default {
  Mutation: {
    ...appsLocal.Mutation,
    ...clientsLocal.Mutation,
  },
  defaults: {
    ...appsLocal.defaults,
    ...clientsLocal.defaults,
  },
};
