import React, { Component } from 'react';
import Input from 'components/Input';
import styles from './index.scss';

class TopBarSearch extends Component {
  state = {
    searchValue: '',
  }

  inputRef = React.createRef()

  componentDidMount() {
    this.focus();

    const { match } = this.props;

    this.setState({
      searchValue: match.params.term,
    });
  }

  focus = () => {
    this.inputRef.current.customFocus();
  }

  onChange = (ev) => {
    ev.preventDefault();
    ev.persist();

    this.setState({
      searchValue: ev.target.value,
    });
  }

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const { searchValue } = this.state;
      const { segment, history, match } = this.props;
      const { type = 'auto' } = match.params;

      history.push(`/search/${segment}/${type}/${searchValue || ''}`);
    }
  }

  render() {
    const { searchValue } = this.state;
    const { pageInfo, placeholder, hideTotal } = this.props;
    const { total, type } = pageInfo || {};

    return (
      <div className={styles.search}>
        {
          (!hideTotal && total && total > 0)
            ? <span className={styles.totalResults}>{`${total} result${total > 1 ? 's' : ''}`}</span>
            : null
        }
        <Input
          ref={this.inputRef}
          autoFocus
          darkTheme
          type="text"
          placeholder={placeholder || ''}
          autoComplete={`search${type}`}
          value={searchValue || ''}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
        />
      </div>
    );
  }
}

export default TopBarSearch;
