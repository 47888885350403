exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".healthCheck_1Dg{width:700px}.row_3lG{display:flex;padding:4px 8px;width:100%;white-space:nowrap}.row_3lG .key_EVE{position:relative;display:flex;align-items:center;min-width:160px}.row_3lG .key_EVE::after{content:':';position:absolute;right:10px}.row_3lG .value_2ks{display:flex;align-items:center;justify-content:space-between;width:100%;padding-right:20px}.row_3lG .value_2ks pre{width:460px;overflow:hidden;max-height:fit-content}.row_3lG pre{max-height:140px;overflow:scroll;padding:4px;border:1px solid #eee}.rowHighlight_ttk:hover{background-color:#f1f4f8}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/ticketing/snapshot/components/HealthCheck/src/modules/ticketing/snapshot/components/HealthCheck/index.scss"],"names":[],"mappings":"AAAA,iBACE,WAAY,CACb,SAEC,aACA,gBACA,WACA,kBAAmB,CAJrB,kBAOI,kBACA,aACA,mBACA,eAAgB,CAVpB,yBAaM,YACA,kBACA,UAAW,CAfjB,oBAoBI,aACA,mBACA,8BACA,WACA,kBAAmB,CAxBvB,wBA2BM,YACA,gBACA,sBAAuB,CA7B7B,aAkCI,iBACA,gBACA,YACA,qBAAsB,CACvB,wBAKC,wBAAyB,CAC1B","file":"index.scss","sourcesContent":[".healthCheck {\n  width: 700px;\n}\n.row {\n  display: flex;\n  padding: 4px 8px;\n  width: 100%;\n  white-space: nowrap;\n\n  .key {\n    position: relative;\n    display: flex;\n    align-items: center;\n    min-width: 160px;\n\n    &::after {\n      content: ':';\n      position: absolute;\n      right: 10px;\n    }\n  }\n\n  .value {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding-right: 20px;\n\n    pre {\n      width: 460px;\n      overflow: hidden;\n      max-height: fit-content;\n    }\n  }\n\n  pre {\n    max-height: 140px;\n    overflow: scroll;\n    padding: 4px;\n    border: 1px solid #eee;\n  }\n}\n\n.rowHighlight {\n  &:hover {\n    background-color: #f1f4f8;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"healthCheck": "healthCheck_1Dg",
	"row": "row_3lG",
	"key": "key_EVE",
	"value": "value_2ks",
	"rowHighlight": "rowHighlight_ttk"
};