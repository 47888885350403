import React from 'react';

import styles from './index.scss';

const FormHolder = ({ children }) => (
  <div className={styles.formHolder}>
    {children}
  </div>
);

export default FormHolder;
