import React from 'react';

const Icon = ({ width, height, fill, name, className }) => {
  const attributes = {
    className,
    width,
    height,
    fill: fill || null,
  };

  const path = `#${name}`;

  return (
    <svg {...attributes} fill={fill || null}>
      <use xlinkHref={`${path}`} />
    </svg>
  );
};

export default Icon;
