import { graphql } from 'react-apollo';

import {
  withUpdateAuth,
  redirectWhenAuthorized,
} from 'app/Auth';

import { withResetLogin } from 'app/local/login';
import { compose } from 'utils/compose';

import Login from './graphql/login.graphql';
import LoginFormData from './graphql/login.local.graphql';
import Form from './Form';

const login = mutate => (email, password) => mutate({
  variables: { email, password },
  // update: (proxy, { data: { createTodo } }) => {
  //   const data = {};
  //   data.todos.push(createTodo);
  //   proxy.writeQuery({ query, data });
  // },
});

export default compose(
  // Disallow for already authorized sessions
  redirectWhenAuthorized(),
  // Provide setSession func prop
  withUpdateAuth,
  // Provide resetLogin form data func prop
  withResetLogin,
  // Provide login form error and message data props
  graphql(LoginFormData, {
    props: ({ data }) => ({ formData: { ...data.login } }),
  }),
  // Provide login request prop
  graphql(Login, {
    props: ({ mutate }) => ({ login: login(mutate) }),
  })
)(Form);
