exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".table_1yK{width:700px;margin:0;border-collapse:collapse}.table_1yK td,.table_1yK th{border:1px solid #eee;padding:10px 20px;text-align:left}.table_1yK thead{background-color:#f1f4f8}.table_1yK thead th{color:#3E3F42;font-size:12px;font-weight:400;height:30px;padding:0 20px;text-transform:uppercase}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/ticketing/snapshot/components/Deploys/src/modules/ticketing/snapshot/components/Deploys/index.scss"],"names":[],"mappings":"AAAA,WACE,YACA,SACA,wBAAyB,CAH3B,4BAMI,sBACA,kBACA,eAAgB,CARpB,iBAYI,wBAAyB,CAZ7B,oBAeM,cACA,eACA,gBACA,YACA,eACA,wBAAyB,CAC1B","file":"index.scss","sourcesContent":[".table {\n  width: 700px;\n  margin: 0;\n  border-collapse: collapse;\n\n  td, th {\n    border: 1px solid #eee;\n    padding: 10px 20px;\n    text-align: left;\n  }\n\n  thead {\n    background-color: #f1f4f8;\n\n    th {\n      color: #3E3F42;\n      font-size: 12px;\n      font-weight: 400;\n      height: 30px;\n      padding: 0 20px;\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "table_1yK"
};