import React, { Component } from 'react';
import ScreenErrors from 'components/ScreenErrors';
import { compose, withRouterProps } from 'utils/compose';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withSearchClients, withSetClientsPageInfo } from './enhancers';
import List from './list';

import styles from '../index.scss';

class View extends Component {
  componentDidUpdate(prevProps) {
    const { data, loading } = this.props;
    const currentData = data || {};
    const prevData = prevProps.data || {};

    if (
      prevData.clients
      && currentData.clients.pageInfo.total !== prevData.clients.pageInfo.total
    ) {
      const { setInfo } = this.props;
      setInfo(currentData.clients.pageInfo);
      return;
    }

    if (prevProps.loading === true && loading === false) {
      const { setInfo, data: dataClients } = this.props;
      const { pageInfo } = dataClients.clients;
      setInfo(pageInfo);
    }
  }

  loadMore = () => {
    const {
      fetchMore,
      data,
      type,
      term,
      before,
      first,
      last,
    } = this.props;

    const { pageInfo } = data.clients;
    const { endCursor, hasNextPage } = pageInfo;

    if (!hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          type,
          term,
          after: endCursor,
          before,
          first,
          last,
        },
      },
      updateQuery: (oldData, { fetchMoreResult: newData }) => {
        const result = {
          viewer: {
            clients: {
              __typename: oldData.viewer.clients.__typename,
            },
            __typename: oldData.viewer.__typename,
          },
        };

        result.viewer.clients.pageInfo = newData.viewer.clients.pageInfo;
        result.viewer.clients.edges = [
          ...oldData.viewer.clients.edges,
          ...newData.viewer.clients.edges,
        ];

        return result;
      },
    });
  }

  render() {
    const {
      term,
      errors,
      loading,
      data,
      history,
    } = this.props;

    if (loading) return null;

    if (errors.length > 0) {
      return (
        <ScreenErrors errors={errors} />
      );
    }

    const { pageInfo, edges: results } = data.clients;

    if (results.length === 0) {
      return (
        <ScreenErrors errors={[`Your search <b>- ${term} -</b> did not match any results.`]} />
      );
    }

    const { hasNextPage } = pageInfo;

    const endMessage = hasNextPage
      ? null
      : <p className={styles.loadingDone}>Yay! You have seen it all</p>;

    if (results.length === 1) {
      const { id } = results[0].node;
      // prevent react warning: Cannot update during an existing state transition
      setTimeout(() => {
        history.replace(`/client/${id}/info`);
      }, 50);

      return null;
    }

    return (
      <InfiniteScroll
        dataLength={results.length} // This is important field to render the next data
        next={this.loadMore}
        hasMore={hasNextPage}
        height={window.innerHeight - 70}
        loader={(
          <p className={styles.loadingMessage}>Loading ...</p>
        )}
        endMessage={endMessage}
      >
        <List data={results} />
      </InfiniteScroll>
    );
  }
}

export default compose(
  withRouterProps,
  withSearchClients,
  withSetClientsPageInfo
)(View);
