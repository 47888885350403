exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".error_2FW{font-size:14px;font-weight:300;border-radius:4px;color:#fff;padding:4px 8px;background-color:#E6492D}.success_3HF{font-size:14px;font-weight:300;border-radius:4px;color:#fff;padding:4px 8px;background-color:#0b7908}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/components/Notify/src/components/Notify/index.scss"],"names":[],"mappings":"AAAA,WACE,eACA,gBACA,kBACA,WACA,gBACA,wBAAyB,CAC1B,aAGC,eACA,gBACA,kBACA,WACA,gBACA,wBAAiC,CAClC","file":"index.scss","sourcesContent":[".error {\n  font-size: 14px;\n  font-weight: 300;\n  border-radius: 4px;\n  color: #fff;\n  padding: 4px 8px;\n  background-color: #E6492D;\n}\n\n.success {\n  font-size: 14px;\n  font-weight: 300;\n  border-radius: 4px;\n  color: #fff;\n  padding: 4px 8px;\n  background-color: rgb(11, 121, 8);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"error": "error_2FW",
	"success": "success_3HF"
};