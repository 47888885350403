import React, { Fragment } from 'react';
import Loader from 'components/Loader';
import ScreenErrors from 'components/ScreenErrors';
import ProgressPie from 'components/ProgressPie';
import Icon from 'components/Icon';
import withAppInfo from './enhancers';

import styles from './index.scss';

const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { app } = data || {};

  let content;

  if (app) {
    const { appSettings } = app;
    const { enginesInfo } = appSettings;
    const { min, max, auto, highlyAvailable, name, currentPods } = enginesInfo;

    content = (
      <Fragment>
        <div className={styles.rowsHolder}>
          <div className={styles.row}>
            {
              auto
                ? <Icon name="icon-check" width={14} height={14} fill="#1bb933" />
                : <Icon name="icon-close" width={14} height={10} fill="#fd6c53" />
            }
            Auto-Scaling
          </div>

          <div className={styles.row}>
            {
              highlyAvailable
                ? <Icon name="icon-check" width={14} height={14} fill="#1bb933" />
                : <Icon name="icon-close" width={14} height={10} fill="#fd6c53" />
            }
            High Availability
          </div>
        </div>

        <ProgressPie
          disabled
          currentPods={currentPods}
          autoscaling={auto}
          highlyAvailable={highlyAvailable}
          min={min}
          max={max}
        />

        <div className={styles.name}>{name}</div>
      </Fragment>
    );
  }

  return (
    <div className={styles.info}>
      <Loader loading={loading}>
        {content}
      </Loader>
    </div>
  );
};

export default withAppInfo(Results);
