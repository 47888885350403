import React from 'react';
import ScreenErrors from 'components/ScreenErrors';
import Loader from 'components/Loader';
import { AppInfoFull } from 'components/App';
import withAppInfo from './enhancers';
import styles from './index.scss';

const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { app } = data || {};

  return (
    <Loader loading={loading} padding="40px 20px">
      <div className={styles.details}>
        <AppInfoFull data={app} />
      </div>
    </Loader>
  );
};

export default withAppInfo(Results);
