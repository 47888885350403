import React from 'react';
import Gravatar from 'react-gravatar';
import Icon from 'components/Icon';
import { toDate, anchorTargetForIframe } from 'utils/misc';
import ScreenErrors from 'components/ScreenErrors';
import classnames from 'classnames';
import Row from 'components/Row';
import { AppInfoShort } from 'components/App';
import { Link } from 'react-router-dom';
import styles from './index.scss';


const Details = ({ data }) => {
  const {
    id,
    email,
    createdAt,
    updatedAt,
    pin,
    image,
    useGravatar,
    gitHubUsername,
    backupsEnabled,
    backupsEnabledFrom,
    billingInfo,
    agreeReceiveNewsletter,
    products,
  } = data;

  console.log('data', data);

  if (!billingInfo) {
    return <ScreenErrors errors={['No billing info!']} />;
  }

  const imageURL = () => {
    if (image) {
      return `https://api-parseground.s3.amazonaws.com/${image}`;
    }

    return '/assets/images/avatar.png';
  };


  const { card } = billingInfo || {};
  const { cardType } = card || {};
  const creditCardIcon = ['american_express', 'mastercard', 'visa', 'discover'].includes(cardType)
    ? `icon-${cardType}`
    : 'icon-credit-card';


  const subscriptionStatus = () => {
    const { status } = billingInfo.subscription || {};
    if (!status) return null;

    const formatedStatus = status.replace('_', ' ');

    return (
      <div
        className={
          classnames(styles.subscriptionStatus, styles[formatedStatus.replace(' ', '')])
        }
      >
        {formatedStatus}
      </div>
    );
  };

  const { apps } = products;
  return (
    <div className={styles.details}>
      <div className={styles.client} key={id}>
        <div className={styles.col1}>
          {
            useGravatar
              ? <Gravatar email={email} size={120} default="mm" />
              : <img src={imageURL()} alt={email} />
          }
          <span className={styles.email}>{subscriptionStatus()}</span>
        </div>

        <div className={styles.col2}>
          <Row title="Email" value={email} />
          <Row title="Client ID" value={id} />
          <Row title="Created At" value={toDate(createdAt)} />
          <Row title="Updated At" value={toDate(updatedAt)} />
          <Row title="PIN" value={pin} />
          <Row title="GitHub Username" value={gitHubUsername || 'not connected'} />
          <Row
            title="Automatic Database Backups Service"
            value={
              backupsEnabled && backupsEnabledFrom
                ? (
                  <div className={styles.twoCols}>
                    {`Activated on ${toDate(backupsEnabledFrom)}`}
                    <span>
                      <Icon name="icon-check" width={16} height={16} fill="#1bb933" />
                    </span>
                  </div>
                )
                : (
                  <div className={styles.twoCols}>
                    {'not active'}
                    <span>
                      <Icon name="icon-close" width={16} height={12} fill="#fd6c53" />
                    </span>
                  </div>
                )
            }
            disableCopy
          />
          {
            cardType
              ? (
                <Row
                  title="Payment Method"
                  disableCopy
                  value={
                    creditCardIcon
                      && (
                        <div className={styles.twoCols}>
                          <div>
                            <Icon width={28} height={28} name={creditCardIcon} colored />
                            <span>{`**** **** **** **** ${card.last4}`}</span>
                          </div>
                          <span>{card.expires}</span>
                        </div>
                      )
                  }
                />
              )
              : null
          }

          <Row
            title="Agree with Receiving Newsletter"
            disableCopy
            value={
              agreeReceiveNewsletter
                ? (
                  <div className={styles.twoCols}>
                    &nbsp;
                    <span>
                      <Icon name="icon-check" width={16} height={16} fill="#1bb933" />
                    </span>
                  </div>
                )
                : (
                  <div className={styles.twoCols}>
                    &nbsp;
                    <span>
                      <Icon name="icon-close" width={16} height={12} fill="#fd6c53" />
                    </span>
                  </div>
                )
            }
          />
        </div>
        <div className={styles.col3}>
          <a
            rel="noopener noreferrer"
            href={`https://sashido.chargebee.com/admin-console/customers/${billingInfo.subscription.customerId}`}
            title="Chargebee - Customer"
            target="_blank"
          >
            <span className={styles.icon}>
              <img src="/assets/images/logos/chargebee.png" alt="Chargebee - Customer" />
            </span>
            <span>Customer</span>
          </a>

          <a
            rel="noopener noreferrer"
            href={`https://sashido.chargebee.com/admin-console/subscriptions/${billingInfo.subscription.id}`}
            title="Chargebee - Subscription"
            target="_blank"
          >
            <span className={styles.icon}>
              <img src="/assets/images/logos/chargebee.png" alt="Chargebee - Subscription" />
            </span>
            <span>Subscription</span>
          </a>

          <a
            rel="noopener noreferrer"
            title="Intercom - User Profile"
            href={`https://app.intercom.io/apps/ckqg6zry/users/show?user_id=${id}`}
            target="_blank"
          >
            <span className={styles.icon}>
              <img src="/assets/images/logos/intercom.png" alt="Intercom - User Profile" />
            </span>
            <span>Profile</span>
          </a>

          <a
            rel="noopener noreferrer"
            href={`https://parseground.teamwork.com/desk/#/search/tickets/q/${email}`}
            title="TeamDesk - Tickets"
            target="_blank"
          >
            <span className={styles.icon}>
              <img src="/assets/images/logos/teamwork.png" alt="TeamDesk - Tickets" />
            </span>
            <span>Tickets</span>
          </a>

          {
            gitHubUsername
            && (
              <a
                rel="noopener noreferrer"
                href={`https://github.com/${gitHubUsername}`}
                title="GitHub - Profile"
                target="_blank"
              >
                <span className={styles.icon}>
                  <Icon name="icon-github" width={20} height={20} />
                </span>
                <span>Profile</span>
              </a>
            )
          }

          <Link
            to={`/client/${id}/activity`}
            className={styles.type}
            target={anchorTargetForIframe}
          >
            <span>Activity Log</span>
          </Link>

        </div>
      </div>

      { apps.map(app => <AppInfoShort data={app} key={app.id} />) }
    </div>
  );
};

export default Details;
