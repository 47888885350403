import React from 'react';
import { withUpdateAuth, showWhenAuthorized, withAuth } from 'app/Auth';
import { compose } from 'utils/compose';
import DropDownMenu from 'components/DropDownMenu';
import styles from './index.scss';

const TopBarUserProfile = ({ auth }) => (
  <div className={styles.topBarUserProfile}>
    <DropDownMenu user={auth.user} />
  </div>
);

export default compose(
  showWhenAuthorized,
  withAuth,
  withUpdateAuth
)(TopBarUserProfile);
