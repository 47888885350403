import React, { Fragment } from 'react';
import { showWhenAdmin, showWhenSupport } from 'app/Auth';
import Tabs from 'components/Tabs';
import ScreenErrors from 'components/ScreenErrors';
import Loader from 'components/Loader';
import Application from './Application';
import withTicketInfo from './enhancers';
import SnapShot from './snapshot';

import Client from './Client';

import styles from './index.scss';

const AdminView = showWhenAdmin(Client);
const SupportView = showWhenSupport(Client);

const Results = ({ errors, loading, data }) => {
  if (errors.length > 0) {
    return (
      <ScreenErrors errors={errors} />
    );
  }

  const { ticket } = data || {};

  const { createdBy, appId } = ticket || {};

  if (!createdBy) return null;


  return (
    <Loader loading={loading}>
      <div className={styles.details}>
        <Tabs contentClassName={styles.tabHolder}>
          {
            appId && (
              <div label="Snapshot">
                <SnapShot ticket={ticket} />
              </div>
            )
          }
          {
            appId && (
              <div label="Current App">
                <Application objectId={appId} />
              </div>
            )
          }
          <div label="Current Client">
            <Fragment>
              <AdminView objectId={createdBy} />
              <SupportView objectId={createdBy} />
            </Fragment>
          </div>
        </Tabs>
      </div>
    </Loader>
  );
};

export default withTicketInfo(Results);
