import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from 'components/Icon';
import styles from './index.scss';


export default class Row extends Component {
  state = {
    copied: false,
  };

  handleCopy = () => {
    setTimeout(() => this.setState({ copied: false }), 800);

    this.setState({
      copied: true,
    });
  }

  render() {
    const { value, title, disableCopy } = this.props;
    const { copied } = this.state;
    const classes = ['copy', styles.copy, copied ? styles.active : null];
    const disabled = disableCopy || typeof value === 'boolean';

    let data = [];

    if (Array.isArray(value)) {
      value.forEach(v => data.push(v));
    } else {
      data.push(value);
    }

    data = data.toString();

    return (
      <div key={title} className={styles.row}>
        <div className={styles.label}>{title}</div>
        <CopyToClipboard text={data} onCopy={this.handleCopy}>
          {
            disabled
              ? <span />
              : (
                <span className={classes.join(' ')}>
                  {copied
                    ? <Icon name="icon-check" width={16} height={16} />
                    : <Icon name="icon-copy" width={16} height={16} />
                  }
                </span>
              )
          }
        </CopyToClipboard>
        {
          typeof value === 'boolean' || value === null
            ? (
              <div className={styles.value}>
                {
                  value
                    ? <Icon name="icon-check" width={16} height={16} fill="#1bb933" />
                    : <Icon name="icon-close" width={16} height={12} fill="#fd6c53" />
                }
              </div>
            )
            : (
              <div className={styles.value}>
                {
                  value || '...'
                }
              </div>
            )
        }
      </div>
    );
  }
}
