exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".authWrapper_3iK{display:flex;flex-direction:column;align-items:center;justify-content:center;position:absolute;top:50%;left:50%;width:320px;transform:translate(-50%, -50%)}.authWrapper_3iK a{display:inline-block;margin-top:20px;padding-bottom:2px;color:#0af;font-size:14px;font-weight:300;border-bottom:1px solid rgba(0,170,255,0.4)}.authWrapper_3iK a:hover{color:#0099e6}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/auth/src/modules/auth/Auth.scss"],"names":[],"mappings":"AAAA,iBACE,aACA,sBACA,mBACA,uBACA,kBACA,QACA,SACA,YACA,+BAAgC,CATlC,mBAYI,qBACA,gBACA,mBACA,WACA,eACA,gBACA,2CAAkC,CAlBtC,yBAqBM,aAAuB,CACxB","file":"Auth.scss","sourcesContent":[".authWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 320px;\n  transform: translate(-50%, -50%);\n\n  a {\n    display: inline-block;\n    margin-top: 20px;\n    padding-bottom: 2px;\n    color: #0af;\n    font-size: 14px;\n    font-weight: 300;\n    border-bottom: 1px solid rgba(#0af, .4);\n\n    &:hover {\n      color: darken(#0af, 5%);\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"authWrapper": "authWrapper_3iK"
};