import ApolloClient from 'apollo-boost';
import { Query, Mutation, defaults } from 'modules';
import { useConfig, getCustomConfig } from 'utils/misc';

export const appId = process.env.SASHIDO_API_APP_ID;
export const graphqlUri = process.env.SASHIDO_GRAPHQL_URL;

console.info('-------');
console.info('-------', graphqlUri);
console.info('-------');

const config = useConfig({
  appId,
  graphqlUri,
}, getCustomConfig());

const client = new ApolloClient({
  uri: config.graphqlUri,
  clientState: {
    defaults: {
      ...defaults,
    },
    resolvers: {
      Query: {
        ...Query,
      },
      Mutation: {
        ...Mutation,
      },
    },
  },
  request: async (operation) => {
    const sessionToken = localStorage.getItem('sessionToken');
    const headers = {
      'x-parse-application-id': config.appId,
    };
    if (sessionToken) {
      headers['x-parse-session-token'] = sessionToken;
    }
    operation.setContext({
      headers,
    });
  },
});

export default client;
