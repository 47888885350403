import React from 'react';
import classNames from 'classnames';
import Tooltip from 'react-autotip';
import numeral from 'numeral';
import Icon from 'components/Icon';
import { SplitTime } from 'utils/misc';
import Gauge from '../Gauge';
import styles from './index.scss';

const mbConverter = (mb) => {
  let bytes;
  if (mb.includes('M')) {
    const m = Number(mb.replace('M', ''));
    bytes = m * 1024 * 1024;
  } else if (mb.includes('G', '')) {
    const m = Number(mb.replace('G'));
    bytes = m * 1024 * 1024 * 1024;
  }

  return bytes;
};

const ProcessInfoLatest = ({ data, lastDeploy }) => {
  if (!data || !lastDeploy) return null;

  const limitRam = mbConverter(lastDeploy.limits.limitRAM);

  const renderProcessInfoLatest = Object.keys(data).map((key) => {
    const title = key;

    const items = data[key].map((item, i) => {
      const name = item.name.toUpperCase();
      const upTime = SplitTime(item.uptime_ms);

      return (
        <tr key={item.name} className={classNames(styles[item.state])}>
          <td>
            <div className={styles.info}>
            <Tooltip
              text={`${name} is ${item.state}`}
              tooltipStyles={{
                color: '#fff',
                fontFamily: 'Ubuntu',
                fontSize: 14,
                fontWeigt: 300,
                borderRadius: 4,
                maxWidth: 600,
                unit: 4,
                lightgrey: '#222933',
                opaqueGrey: '#222933',
              }}
            >
              <h3 className={styles.title}>
                <span className={classNames(styles[item.state])} />
                {item.name}
              </h3>
            </Tooltip>
              {
                item.uptime_ms !== 0
                  && (
                    <Tooltip
                      text={`Uptime - ${upTime} `}
                      tooltipStyles={{
                        color: '#fff',
                        fontFamily: 'Ubuntu',
                        fontSize: 14,
                        fontWeigt: 300,
                        borderRadius: 4,
                        maxWidth: 600,
                        unit: 4,
                        lightgrey: '#222933',
                        opaqueGrey: '#222933',
                      }}
                    >
                      <div className={styles.uptime}>
                        <Icon name="icon-clock" width="14" height="14" />
                        {upTime}
                      </div>
                    </Tooltip>
                  )
              }
            </div>
          </td>
          <td>
            <div className={styles.restarts}>{item.restarts_count}</div>
          </td>
          <td>
            <Gauge
              id={`${key}-${item.name}-cpu`}
              percent={item.cpu / 100}
              min="0"
              max="100%"
              state={item.state}
              value={Number(item.cpu).toFixed(2)}
            />
          </td>
          <td>
            <Gauge
              id={`${key}-${item.name}-mem`}
              percent={(item.mem / limitRam).toPrecision(2)}
              min="0"
              max="512M"
              state={item.state}
              value={numeral(item.mem).format('0.00b')}
            />
          </td>
          <td>
            <Gauge
              id={`${key}-${item.name}-eld`}
              percent={(item.event_loop_delay / 100).toPrecision(2)}
              min="0"
              max="100ms"
              state={item.state}
              value={Number(item.event_loop_delay).toFixed(2)}
            />
          </td>
        </tr>
      );
    });

    return (
      <div key={key} className={styles.item}>
        <div className={styles.itemHeader}>
          <h4>{title}</h4>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Restarts</th>
              <th>CPU</th>
              <th>MEMORY</th>
              <th>ELD</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </table>
      </div>
    );
  });

  return (
    <div className={styles.processInfoLatest}>
      {renderProcessInfoLatest}
    </div>
  );
};

export default ProcessInfoLatest;
