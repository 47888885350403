import React from 'react';
import { Link } from 'react-router-dom';

import styles from './index.scss';

export default () => (
  <div className={styles.wrapper}>
    <h1>This route is not found</h1>
    <Link to="/">Go Home!</Link>
  </div>
);
