exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".topBarUserProfile_3-r{display:flex;align-items:center}.topBarUserProfile_3-r .avatar_36i{margin-right:20px}.topBarUserProfile_3-r img{max-width:40px;max-height:40px;width:100%;height:100%;border-radius:50%}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/auth/TopBarUserProfile/src/modules/auth/TopBarUserProfile/index.scss"],"names":[],"mappings":"AAAA,uBACE,aACA,kBAAmB,CAFrB,mCAKI,iBAAkB,CALtB,2BASI,eACA,gBACA,WACA,YACA,iBAAkB,CACnB","file":"index.scss","sourcesContent":[".topBarUserProfile {\n  display: flex;\n  align-items: center;\n\n  .avatar {\n    margin-right: 20px;\n  }\n\n  img {\n    max-width: 40px;\n    max-height: 40px;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"topBarUserProfile": "topBarUserProfile_3-r",
	"avatar": "avatar_36i"
};