import React, { Component } from 'react';
import Icon from 'components/Icon';
import CopyToClipboard from 'react-copy-to-clipboard';

import styles from './index.scss';

export default class Copy extends Component {
  state = {
    copied: false,
  }

  handleCopy = () => {
    setTimeout(() => this.setState({ copied: false }), 800);

    this.setState({
      copied: true,
    });
  }

  render() {
    const { data, customStyle, activeStyle } = this.props;
    const { copied } = this.state;
    const classes = ['copy', styles.copy, customStyle, copied ? activeStyle : null];

    return (
      <div className={classes.join(' ')}>
        <CopyToClipboard text={data} onCopy={this.handleCopy}>
          <span>
            {
              copied
                ? <Icon name="icon-check" width={16} height={16} />
                : <Icon name="icon-copy" width={16} height={16} />
            }
          </span>
        </CopyToClipboard>
      </div>
    );
  }
}
