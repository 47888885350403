import React from 'react';
import { redirectWhenNotAuthorized } from 'app/Auth';
import { compose, withRouterProps } from 'utils/compose';
import View from './View';

export { default as TopBarSearchApps } from './TopBarSearch';


const Apps = ({ match }) => {
  const { type = '', term = '' } = match.params;

  return (
    <View
      type={type.toUpperCase()}
      term={term}
      first={20}
    />
  );
};

export default compose(
  redirectWhenNotAuthorized(),
  withRouterProps
)(Apps);
