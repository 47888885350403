import React, { PureComponent } from 'react';
import classnames from 'classnames';

import cls from './index.scss';

class Input extends PureComponent {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
  }

  ref = React.createRef();

  customFocus = () => {
    this.ref.current.focus();
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      error,
      darkTheme,
      ...rest
    } = this.props;

    return (
      <div {...wrapperProps} className={classnames(cls.inputHolder, wrapperProps.className, darkTheme ? cls.darkTheme : '')}>
        <label htmlFor={name} className={cls.inputLabel}>
          <div className={cls.inputWrapper}>
            <input
              className={classnames(className, cls.input)}
              name={name}
              type="text"
              ref={this.ref}
              {...rest}
            />

            {error && <span>{error}</span>}
          </div>
        </label>
      </div>
    );
  }
}

export default Input;
