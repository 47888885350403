import React, { Fragment } from 'react';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import Icon from 'components/Icon';
import CopyRow from 'components/CopyRow';

import styles from './index.scss';

const HealthCheck = ({ data }) => {
  let jsonHeaders = {};
  if (data.response && data.response.headers) {
    jsonHeaders = cloneDeep(data.response.headers);
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    jsonHeaders = JSON.parse(JSON.stringify(jsonHeaders), omitTypename);
  }

  let jsonBody = {};
  if (data.response && data.response.body) {
    jsonBody = cloneDeep(data.response.body);
    jsonBody = JSON.parse(JSON.stringify(jsonBody));
  }


  return (
    <div className={styles.healthCheck}>
      <CopyRow data={data.statusCode} className={styles.rowHighlight}>
        <div className={styles.row}>
          <div className={styles.key}>statusCode</div>
          <div className={styles.value}>
            <span>{data.statusCode}</span>
          </div>
        </div>
      </CopyRow>
      <CopyRow data={data.statusCode} className={styles.rowHighlight}>
        <div className={styles.row}>
          <div className={styles.key}>reason</div>
          <div className={styles.value}>
            <span>{data.reason}</span>
          </div>
        </div>
      </CopyRow>
      <CopyRow data={data.statusCode} className={styles.rowHighlight}>
        <div className={styles.row}>
          <div className={styles.key}>Response Time (Ms)</div>
          <div className={styles.value}>
            <span>{data.responseTimeMs}</span>
          </div>
        </div>
      </CopyRow>

      <CopyRow data={JSON.stringify(jsonHeaders, null, 2)} className={styles.rowHighlight}>
        <div className={styles.row}>
          <div className={styles.key}>Response Headers</div>
          <div className={styles.value}>
            <pre>{JSON.stringify(jsonHeaders, null, 2)}</pre>
          </div>
        </div>
      </CopyRow>

      <CopyRow data={JSON.stringify(jsonBody, null, 2)} className={styles.rowHighlight}>
        <div className={styles.row}>
          <div className={styles.key}>Response Body</div>
          <div className={styles.value}>
            <pre>{JSON.stringify(jsonBody, null, 2)}</pre>
          </div>
        </div>
      </CopyRow>
    </div>
  );
};

export default HealthCheck;
