import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import View from './View';

export default {
  routes: [
    <Redirect key="appInfoRedirect" exact from="/app/:objectId" to="/app/:objectId/info" />,
    <Route key="appInfo" exact path="/app/:objectId/info" component={View} />,
  ],
};
