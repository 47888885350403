import React from 'react';
import { toDate } from 'utils/misc';
import Tooltip from 'react-autotip';
import Icon from 'components/Icon';
import styles from './index.scss';

const browser = (agent) => {
  switch (true) {
    case agent.toLowerCase().indexOf('edge') > -1: return 'edge';
    case agent.toLowerCase().indexOf('opr') > -1 && !!window.opr: return 'Opera';
    case agent.toLowerCase().indexOf('chrome') > -1 && !!window.chrome: return 'Chrome';
    case agent.toLowerCase().indexOf('trident') > -1: return 'IE';
    case agent.toLowerCase().indexOf('firefox') > -1: return 'Firefox';
    case agent.toLowerCase().indexOf('safari') > -1: return 'Safari';
    default: return 'other';
  }
};


const List = ({ data }) => {
  const items = data.map(({ node: item }) => {
    const { message } = item.data || {};
    return (
      <div
        key={item.id}
        className={styles.item}
      >
        <span className={styles.col1}>{toDate(item.createdAt)}</span>
        <span className={styles.col2}>{message}</span>
        <span className={styles.col3}>{item.ip}</span>
        <span className={styles.col4}>
          {browser(item.browser)}
          <Tooltip
            text={<div dangerouslySetInnerHTML={{ __html: item.browser }} />}
            tooltipStyles={{
              color: '#fff',
              fontFamily: 'Ubuntu',
              fontSize: 14,
              fontWeigt: 300,
              borderRadius: 4,
              maxWidth: 600,
              unit: 4,
              lightgrey: '#222933',
              opaqueGrey: '#222933',
            }}
          >
            <Icon name="icon-info" width={16} height={16} fill="#47689f" />
          </Tooltip>
        </span>
      </div>
    );
  });

  return (
    <div className={styles.listWrapper}>
      <div className={styles.list}>
        {
          items
        }
      </div>
    </div>
  );
};

export default List;
