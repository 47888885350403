import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './index.scss';

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
        status,
      },
    } = this;

    const className = [styles.item];

    if (activeTab === label) {
      className.push(styles.active);
    }

    return (
      <li // eslint-disable-line
        className={className.join(' ')}
        onClick={onClick}
      >
        {
          status && (
            <span className={classNames(styles.dot, styles[status])} />
          )
        }
        {label}
      </li>
    );
  }
}


export default Tab;
