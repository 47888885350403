

export default {
  Mutation: {
    startLoading(_, __, { cache }) {
      cache.writeData({
        data: {
          screen: {
            __typename: 'Screen',
            loading: true,
            stopLoading: false,
            mounted: true,
          },
        },
      });
      return null;
    },
    stopLoading(_, __, { cache }) {
      cache.writeData({
        data: {
          screen: {
            __typename: 'Screen',
            loading: true,
            stopLoading: true,
            mounted: true,
          },
        },
      });
      return null;
    },
    resetLoading(_, __, { cache }) {
      cache.writeData({
        data: {
          screen: {
            __typename: 'Screen',
            loading: false,
            stopLoading: false,
            mounted: true,
          },
        },
      });
      return null;
    },
  },
  defaults: {
    // Application loader, default to true to avoid flicking screens
    // See app/Auth/Checker for more info
    screen: {
      __typename: 'Screen',
      loading: true,
      stopLoading: false,
      mounted: false,
    },
  },
};
