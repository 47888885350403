import { graphql } from 'react-apollo';

import { queryErrorsToArray } from 'utils/misc';
import { TicketInfo } from './graphql/ticket.graphql';

const withTicketInfo = graphql(TicketInfo, {
  props: ({ data: { viewer, error, loading } }) => ({
    errors: queryErrorsToArray(error),
    loading,
    data: viewer,
  }),
  options: props => ({
    variables: {
      id: props.id,
    },
  }),
});

export default withTicketInfo;
