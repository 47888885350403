import React from 'react';
import { withRouterProps } from 'utils/compose';
import Gravatar from 'react-gravatar';
import styles from './index.scss';

const List = ({ history, data }) => {
  const clients = data.map(({ node: client }) => (
    <div
      className={styles.client}
      key={client.id}
    >
      {
        /* eslint-disable */
        !client.image
          ? (
            <Gravatar
              email={client.email}
              size={120}
              default="mm"
              onClick={() => {
                history.push(`/client/${client.id}/info`);
              }}
            />
          )
          : (
            <img
              src={`https://api-parseground.s3.amazonaws.com/${client.image}`}
              alt={client.email}
              onClick={() => {
                history.push(`/client/${client.id}/info`);
              }}
            />
          )

        /* eslint-enable */
      }
      <span className={styles.email}>{client.email}</span>
    </div>
  ));

  let gridFixStyle;
  if (clients.length < 5) {
    gridFixStyle = { gridTemplateColumns: `repeat(${clients.length}, 240px)` };
  }

  return (
    <div className={styles.clientListWrapper}>
      <div className={styles.clientList} style={gridFixStyle}>
        {clients}
      </div>
    </div>
  );
};

export default withRouterProps(List);
