import { graphql } from 'react-apollo';

import {
  ResetLogin,
  SetLoginMessage,
  SetLoginError,
  SetLoginEmail,
} from './graphql/manage.local.graphql';


export const withResetLogin = graphql(ResetLogin, {
  props: ({ mutate }) => ({ resetLogin: mutate }),
});

export const withSetLoginMessage = graphql(SetLoginMessage, {
  props: ({ mutate }) => ({
    setLoginMessage: message => (
      mutate({ variables: { message } })
    ),
  }),
});

export const withSetLoginError = graphql(SetLoginError, {
  props: ({ mutate }) => ({
    setLoginError: error => (
      mutate({ variables: { error } })
    ),
  }),
});

export const withSetLoginEmail = graphql(SetLoginEmail, {
  props: ({ mutate }) => ({
    setLoginEmail: email => (
      mutate({ variables: { email } })
    ),
  }),
});
