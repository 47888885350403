import React, { PureComponent, Fragment } from 'react';

import { appId, graphqlUri } from 'utils/apollo';
import { withRouterProps } from 'utils/compose';
import { getCustomConfig, useConfig } from 'utils/misc';

class ConfigChecker extends PureComponent {
  componentDidMount() {
    const config = useConfig({ appId, graphqlUri }, getCustomConfig());
    if (!config.appId || !config.graphqlUri) {
      const { history, location } = this.props;
      if (!location.pathname !== '/login') {
        history.push('/login');
      }
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
}

export default withRouterProps(ConfigChecker);
