import React, { Component } from 'react';
import Gravatar from 'react-gravatar';
import { Link } from 'react-router-dom';
import Copy from 'components/Copy';
import styles from './index.scss';


class DropDown extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleKey = (ev) => {
    const keyCode = ev.keyCode || ev.which;
    switch (keyCode) {
      case 27:
        this.setState({ open: false });
        break;
      default:
        break;
    }
  }

  handleClickOutside = (ev) => {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target)) {
      this.setState({ open: false });
    }
  }

  toggleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  render() {
    const classes = [styles.container];
    const { open } = this.state;
    const { children, collaborators, ownerId, appId } = this.props;

    return (
      <div className={classes.join(' ')} ref={this.setWrapperRef}>
        <div className={[styles.btn, open ? styles.open : ''].join(' ')}>
          <span role="presentation" onClick={() => this.toggleClick()} onKeyPress={() => this.toggleClick()}>
            {children}
          </span>
          <ul className={styles.dropdown}>
            {
              collaborators
                .filter(u => u.id !== ownerId)
                .map(({ id, email, useGravatar, image }) => {
                  const imageURL = () => {
                    if (image) {
                      return `https://api-parseground.s3.amazonaws.com/${image}`;
                    }

                    return '/assets/images/avatar.png';
                  };

                  const path = `/client/${id}/info`;
                  return (
                    <li key={`${appId}-${id}`}>
                      <Link
                        to={path}
                        className={styles.type}
                      >
                        {
                          useGravatar && !image
                            ? <Gravatar email={email} size={30} default="mm" />
                            : <img src={imageURL()} alt={email} title={email} />
                        }
                        <span>{email}</span>
                      </Link>
                      <Copy data={email} customStyle={styles.copy} />
                    </li>
                  );
                })
            }
          </ul>
        </div>
      </div>
    );
  }
}


export default DropDown;
