exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".details_3DN{display:flex;align-items:center;justify-content:center;flex-wrap:wrap;align-content:center;font-size:14px}\n", "", {"version":3,"sources":["/Users/pivanov/Documents/workspace/cloudstrap/projects/sashido/projects/graphql/sashido-support-tool/src/modules/app/src/modules/app/index.scss"],"names":[],"mappings":"AAAA,aACE,aACA,mBACA,uBACA,eACA,qBACA,cAAe,CAChB","file":"index.scss","sourcesContent":[".details {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n  align-content: center;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"details": "details_3DN"
};